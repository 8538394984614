import axios from 'axios';
import {
    BASE_URL_SERVICE,
} from '../constants'
import { dateToMysql } from '../utils/dateTime';

/* -------------------------------------------------------------------------- */
/*                               Get Alumnos                              */
/* -------------------------------------------------------------------------- */
// export async function getAlumnosNotas({ usuario_id, alumno_id }) {
//     try {
//         var config = {
//             headers: {
//                 'Access-Control-Allow-Origin': '*',
//                 'content-type': 'text/json',
//                 'Authorization': "04871209347109238740"
//             }
//         };

//         const response = await axios.get(BASE_URL_SERVICE + 'alumno_nota', {
//             params: {
//                 usuario_id,
//                 alumno_id
//             }
//         }, config);

//         return response.data;
//     } catch (error) {
//         return undefined;
//         // throw error;
//     }

// }

/* -------------------------------------------------------------------------- */
/*                               Insertar nota                               */
/* -------------------------------------------------------------------------- */
export async function insert_nota({ usuario_id, alumno_id, nota, fecha_aviso }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);
        formData.append("alumno_id", alumno_id);
        formData.append("nota", nota);
        formData.append("fecha_aviso", dateToMysql(fecha_aviso));

        const response = await axios({
           method: 'post',
           url: BASE_URL_SERVICE + 'alumno/insert_nota',
           data: formData,
           headers: {
            // 'Content-Type': 'multipart/form-data',
            // 'Authorization': "Bearer eyJ0eciOiJSUzI1NiJ9.eyJMiIsInNjb3BlcyI6W119.K3lW1STQhMdxfAxn00E4WWFA3uN3iIA", 
            // 'X-AUTH-TOKEN': "1231231231231231"
           },
    //   crossorigin: true,    
    //   mode: 'no-cors',      
        });

        return response.data;
    } catch (error) {
        return undefined;
        // throw error;
    }

}
export async function insert_nota_seguimiento({ usuario_id, alumno_id, nota, motivo_llamada_id, fecha_aviso }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);
        formData.append("alumno_id", alumno_id);
        formData.append("nota", nota);
        formData.append("motivo_llamada_id", motivo_llamada_id);
        formData.append("fecha_aviso", dateToMysql(fecha_aviso));

        const response = await axios({
           method: 'post',
           url: BASE_URL_SERVICE + 'alumno/insert_nota_seguimiento',
           data: formData,
           headers: {
            // 'Content-Type': 'multipart/form-data',
            // 'Authorization': "Bearer eyJ0eciOiJSUzI1NiJ9.eyJMiIsInNjb3BlcyI6W119.K3lW1STQhMdxfAxn00E4WWFA3uN3iIA", 
            // 'X-AUTH-TOKEN': "1231231231231231"
           },
    //   crossorigin: true,    
    //   mode: 'no-cors',      
        });

        return response.data;
    } catch (error) {
        return undefined;
        // throw error;
    }

}
export async function update_nota_fecha_aviso({ usuario_id, nota_id, fecha_aviso }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);
        formData.append("nota_id", nota_id);
        formData.append("fecha_aviso", dateToMysql(fecha_aviso));

        const response = await axios({
           method: 'post',
           url: BASE_URL_SERVICE + 'alumno/update_nota_fecha_aviso',
           data: formData,
           headers: {
            // 'Content-Type': 'multipart/form-data',
            // 'Authorization': "Bearer eyJ0eciOiJSUzI1NiJ9.eyJMiIsInNjb3BlcyI6W119.K3lW1STQhMdxfAxn00E4WWFA3uN3iIA", 
            // 'X-AUTH-TOKEN': "1231231231231231"
           },
    //   crossorigin: true,    
    //   mode: 'no-cors',      
        });

        return response.data;
    } catch (error) {
        return undefined;
        // throw error;
    }

}
export async function update_nota_seguimiento_fecha_aviso({ usuario_id, nota_id, fecha_aviso }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);
        formData.append("nota_id", nota_id);
        formData.append("fecha_aviso", dateToMysql(fecha_aviso));

        const response = await axios({
           method: 'post',
           url: BASE_URL_SERVICE + 'alumno/update_nota_seguimiento_fecha_aviso',
           data: formData,
           headers: {
            // 'Content-Type': 'multipart/form-data',
            // 'Authorization': "Bearer eyJ0eciOiJSUzI1NiJ9.eyJMiIsInNjb3BlcyI6W119.K3lW1STQhMdxfAxn00E4WWFA3uN3iIA", 
            // 'X-AUTH-TOKEN': "1231231231231231"
           },
    //   crossorigin: true,    
    //   mode: 'no-cors',      
        });

        return response.data;
    } catch (error) {
        return undefined;
        // throw error;
    }

}
export async function update_nota_seguimiento_motivo_llamada({ usuario_id, nota_id, motivo_llamada_id }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);
        formData.append("nota_id", nota_id);
        formData.append("motivo_llamada_id", motivo_llamada_id);

        const response = await axios({
           method: 'post',
           url: BASE_URL_SERVICE + 'alumno/update_nota_seguimiento_motivo_llamada',
           data: formData,
           headers: {
            // 'Content-Type': 'multipart/form-data',
            // 'Authorization': "Bearer eyJ0eciOiJSUzI1NiJ9.eyJMiIsInNjb3BlcyI6W119.K3lW1STQhMdxfAxn00E4WWFA3uN3iIA", 
            // 'X-AUTH-TOKEN': "1231231231231231"
           },
    //   crossorigin: true,    
    //   mode: 'no-cors',      
        });

        return response.data;
    } catch (error) {
        return undefined;
        // throw error;
    }

}

