import { useEffect, useState } from 'react';

const useDataGridFilterPersist = (userId, page, gridName) => {
    const [filterModel, setFilterModel] = useState({
      items: [],
      logicOperator: "and",
      quickFilterLogicOperator: "and"
    })
    const fullName = `${page}_${gridName}_grid_state`

    useEffect(() => {

        const aux = JSON.parse( localStorage.getItem( fullName ) )
        aux && setFilterModel( 
          
          {
            items: aux.items,
            logicOperator: aux.logicOperator,
            quickFilterLogicOperator: aux.quickFilterLogicOperator
          } 
          
        )

    }, [])

    const onFilterModelChange = (filter,details) => { 
      localStorage.setItem(fullName, JSON.stringify(filter) )

      setFilterModel(filter)
    }
  return [
    filterModel,
    onFilterModelChange,
  ];
}

export default useDataGridFilterPersist
