import React, { useEffect, useState } from 'react'

import { Button, Dialog, DialogContent, DialogTitle, Grid, TextField } from '@material-ui/core'

import * as yup from 'yup';
import { useFormik } from 'formik';
import { useSelector } from 'react-redux';
import {  useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { selectUsuarioSeleccionado } from '../../../redux/reducer/loginReducer';
import { getCreador, insertar_usuario_creador, update_usuario_creador } from '../../../services/alumnos';
import Swal from 'sweetalert2';

// const FormObserver = () => {
//     const { values } = useFormikContext() ?? {};
  
//     useEffect(() => {
//       console.log("FormObserver::values", values);
//     }, [values]);
  
//     return null;
//   };

const DialogModalCreador = ( {openModal, setOpenModal, creador_id} ) => {
    const [creador, setCreador] = useState([])

    const usuarioSeleccionado = useSelector(state => {
        return selectUsuarioSeleccionado(state);
    });
    
    const [initialValues, setInitialValues] = useState({
        correo_electronico: "",
        nombre: "",
        apellidos: "",
        telefono: "",
        dni: "",
        direccion: "",
        provincia: "",
        num_cuenta: "",
        correo_electronico_personal: "",
    })
    const validationSchema = yup.object({
        
        correo_electronico: yup.string().required('Correo electronico requerido'),
        nombre: yup.string().required('Nombre requerido'),
        apellidos: yup.string().required('Apellidos requeridos'),
        telefono: yup.string().required('Teléfono requerido'),
        dni: yup.string().required('DNI requerido'),
        direccion: yup.string().required('Dirección requerida'),
        provincia: yup.string().required('Provincia requerida'),
        num_cuenta: yup.string().required('Numero de cuenta requerida'),
        correo_electronico_personal: yup.string().required('Correo electrónico personal requerido'),

    });

    useEffect(() => {
      
      getCreador({usuario_id: creador_id}).then( response => {
        console.log(response)

        if(response.creador) {
          setCreador(response.creador)

          formik.setFieldValue('correo_electronico',response.creador.mail)
          formik.setFieldValue('nombre',response.creador.nombre)
          formik.setFieldValue('apellidos',response.creador.apellidos)
          formik.setFieldValue('telefono',response.creador.telefono)
          formik.setFieldValue('dni',response.creador.dni)
          formik.setFieldValue('direccion',response.creador.direccion)
          formik.setFieldValue('provincia',response.creador.provincia)
          formik.setFieldValue('num_cuenta',response.creador.num_cuenta)
          formik.setFieldValue('correo_electronico_personal',response.creador.correo_electronico_personal)
        }
      })
    
    }, [creador_id])

    const formik = useFormik({
      initialValues: initialValues,
      validationSchema: validationSchema,
      onSubmit: (values, actions) => {
        
        if( creador_id === 0 ) {
          insertar_usuario_creador({usuario_id: usuarioSeleccionado.id, campos: values}).then( response => {
            console.log(response)
            if(!response.usuario_id) {
              Swal.fire("No se ha podido insertar el usuario. Correo electrónico duplicado")
            } else {
              
            }
            setOpenModal(false)
          })
        } else {
          values.creador_id = creador_id
          update_usuario_creador({usuario_id: usuarioSeleccionado.id, campos: values}).then( response => {
              Swal.fire("Actualización realizada")
            setOpenModal(false);
          })
        }
        
        // if(values.telefono === '' && values.correo_electronico === '' ) {
        //   actions.setFieldError('telefono', 'Telefono o correo electrónico obligatorios')
        //   actions.setFieldError('correo_electronico', 'Telefono o correo electrónico obligatorios')
        //   return;
        // }

        // getAlumnosRegistradosBuscar({usuario_id: usuarioSeleccionado.id, telefono: values.telefono, correo_electronico: values.correo_electronico}).then( (response) => {
          
        //     setAlumnos(response.alumnos)
        //     setLoadling(false)

        // } )  

      },
    })

    return (
      <Dialog
        open={openModal}
        onClose={() => setOpenModal(false)}
        maxWidth={"md"}
      >
        <DialogTitle>Ficha creador</DialogTitle>
        <DialogContent style={{ minHeight: "150px" }}>
          <form
            noValidate
            method="POST"
            enctype="multipart/form-data"
            // autoComplete="off"
            onSubmit={formik.handleSubmit}
          >
            <Grid
              container
              spacing={2}
              style={{ flex: 1, width: "100%", paddingLeft: 15 }}
            >
              <Grid item xs={12} md={6}>
                <TextField
                  id="nombre"
                  name="nombre"
                  label="Nombre"
                  placeholder="Nombre"
                  value={formik.values.nombre}
                  onChange={formik.handleChange}
                  variant="outlined"
                  fullWidth
                  error={
                    formik.touched.nombre && Boolean(formik.errors.nombre)
                  }
                  helperText={formik.touched.nombre && formik.errors.nombre}
                  // InputLabelProps={{
                  //     shrink: true,
                  // }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  id="apellidos"
                  name="apellidos"
                  label="Apellidos"
                  placeholder="Apellidos"
                  value={formik.values.apellidos}
                  onChange={formik.handleChange}
                  variant="outlined"
                  fullWidth
                  error={
                    formik.touched.apellidos && Boolean(formik.errors.apellidos)
                  }
                  helperText={formik.touched.apellidos && formik.errors.apellidos}
                  // InputLabelProps={{
                  //     shrink: true,
                  // }}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <TextField
                  id="correo_electronico"
                  name="correo_electronico"
                  label="Correo electrónico"
                  placeholder="Correo electronico"
                  value={formik.values.correo_electronico}
                  onChange={formik.handleChange}
                  variant="outlined"
                  fullWidth
                  error={
                    formik.touched.correo_electronico &&
                    Boolean(formik.errors.correo_electronico)
                  }
                  helperText={
                    formik.touched.correo_electronico &&
                    formik.errors.correo_electronico
                  }
                  // InputLabelProps={{
                  //     shrink: true,
                  // }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  id="telefono"
                  name="telefono"
                  label="Telefono"
                  placeholder="Telefono"
                  value={formik.values.telefono}
                  onChange={formik.handleChange}
                  variant="outlined"
                  fullWidth
                  error={
                    formik.touched.telefono && Boolean(formik.errors.telefono)
                  }
                  helperText={formik.touched.telefono && formik.errors.telefono}
                  // InputLabelProps={{
                  //     shrink: true,
                  // }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  id="dni"
                  name="dni"
                  label="DNI"
                  placeholder="DNI"
                  value={formik.values.dni}
                  onChange={formik.handleChange}
                  variant="outlined"
                  fullWidth
                  error={
                    formik.touched.dni && Boolean(formik.errors.dni)
                  }
                  helperText={formik.touched.dni && formik.errors.dni}
                  // InputLabelProps={{
                  //     shrink: true,
                  // }}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <TextField
                  id="direccion"
                  name="direccion"
                  label="Dirección"
                  placeholder="Dirección"
                  value={formik.values.direccion}
                  onChange={formik.handleChange}
                  variant="outlined"
                  fullWidth
                  error={
                    formik.touched.direccion && Boolean(formik.errors.direccion)
                  }
                  helperText={formik.touched.direccion && formik.errors.direccion}
                  // InputLabelProps={{
                  //     shrink: true,
                  // }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  id="provincia"
                  name="provincia"
                  label="Provincia"
                  placeholder="Provincia"
                  value={formik.values.provincia}
                  onChange={formik.handleChange}
                  variant="outlined"
                  fullWidth
                  error={
                    formik.touched.provincia && Boolean(formik.errors.provincia)
                  }
                  helperText={formik.touched.provincia && formik.errors.provincia}
                  // InputLabelProps={{
                  //     shrink: true,
                  // }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  id="num_cuenta"
                  name="num_cuenta"
                  label="Num cuenta"
                  placeholder="Num cuenta"
                  value={formik.values.num_cuenta}
                  onChange={formik.handleChange}
                  variant="outlined"
                  fullWidth
                  error={
                    formik.touched.num_cuenta && Boolean(formik.errors.num_cuenta)
                  }
                  helperText={formik.touched.num_cuenta && formik.errors.num_cuenta}
                  // InputLabelProps={{
                  //     shrink: true,
                  // }}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <TextField
                  id="correo_electronico_personal"
                  name="correo_electronico_personal"
                  label="Correo electrónico personal"
                  placeholder="Correo electrónico personal"
                  value={formik.values.correo_electronico_personal}
                  onChange={formik.handleChange}
                  variant="outlined"
                  fullWidth
                  error={
                    formik.touched.correo_electronico_personal && Boolean(formik.errors.correo_electronico_personal)
                  }
                  helperText={formik.touched.correo_electronico_personal && formik.errors.correo_electronico_personal}
                  // InputLabelProps={{
                  //     shrink: true,
                  // }}
                />
              </Grid>




              <Grid item xs={8} md={2} style={{marginBottom: 20}}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  type="submit"
                  size="large"
                  style={{ height: 55 }}
                >
                  { creador_id !== 0 ? 'Modificar' : 'Insertar' }
                </Button>
              </Grid>
            </Grid>
          </form>

        </DialogContent>
      </Dialog>
    );
}

export default DialogModalCreador
