import React from 'react';
// import "../../assets/css/master.css";

import TemplateDashboard from "../../components/TemplateDashboard";
import TutoresContent from './components/TutoresContent';

const TutoresListado = () => {

    return (
        <>

            <TemplateDashboard>
                <TutoresContent />
            </TemplateDashboard>

        </>
    )
};

export default TutoresListado;