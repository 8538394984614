import React from 'react';
// import "../../assets/css/master.css";

import TemplateDashboard from "../../components/TemplateDashboard";
import FacturacionCreadoresContent from './components/FacturacionCreadoresContent';

const FacturacionCreadoresListado = () => {

    return (
        <>

            <TemplateDashboard>
                <FacturacionCreadoresContent />
            </TemplateDashboard>

        </>
    )
};

export default FacturacionCreadoresListado;