import React, { useEffect, useRef } from 'react'
import { useState } from "react";

import { makeStyles } from '@material-ui/core/styles';

import LoadingComponent from '../../../../components/Loading';

import { useSelector } from "react-redux";

import {
    selectUsuarioSeleccionado,
} from "../../../../redux/reducer/loginReducer";
import { Avatar, } from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';
import TituloPagina from '../../../../components/Titulos/TituloPagina';
import {  getRingoverLlamadas, update_comercial } from '../../../../services/alumnos';
import { Stack } from '@mui/system';
import { DataGridPro, GridActionsCellItem, GridToolbarDensitySelector, GridToolbarFilterButton, useGridApiRef } from '@mui/x-data-grid-pro';
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import { grey } from '@material-ui/core/colors';

function escapeRegExp(value) {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
  }

// import Moment from 'react-moment';

/* -------------------------------------------------------------------------- */
/*                                   Estilos                                  */
/* -------------------------------------------------------------------------- */
const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: '80px'
    },
    loadingContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "90vh"
    },
    emptyContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "80vh",
        width: '100%',
        flexDirection: 'column',
        textAlign: 'center'
    },
    emptyImage: {
        width: '100%'
    },
    rootFilter: {
        padding: theme.spacing(0.5, 0.5, 0),
        justifyContent: 'space-between',
        display: 'flex',
        alignItems: 'flex-start',
        flexWrap: 'wrap',
      },
    textFieldFilter: {
        [theme.breakpoints.down('xs')]: {
          width: '100%',
        },
        margin: theme.spacing(1, 0.5, 1.5),
        '& .MuiSvgIcon-root': {
          marginRight: theme.spacing(0.5),
        },
        '& .MuiInput-underline:before': {
          borderBottom: `1px solid ${theme.palette.divider}`,
        },
      },
    enlace: {
      color: theme.palette.link.main,
      textDecoration: 'none'
    }
}));

export default function RingoverLlamadasContent() {
    const classes = useStyles();

    const [loading, setLoading] = useState(false)
    const [loadingMensaje, setLoadingMensaje] = useState("")
    const [llamadas, setLlamadas] = useState([])

    const audioRef= useRef();

    const usuarioSeleccionado = useSelector(state => {
        return selectUsuarioSeleccionado(state);
    });
    // const history = useHistory()

    /* -------------------------------------------------------------------------- */
    /*                                  UseEffect                                 */
    /* -------------------------------------------------------------------------- */
    useEffect(() => {
        setLoading(true)
        setLoadingMensaje("Cargando llamadas")

        const usuario_id = usuarioSeleccionado.id

        getRingoverLlamadas({ usuario_id }).then((response) => {
            setLoading(false)

            response && setLlamadas(response.llamadas)
            
        });

    }, [])
    
    
    
    // const setPlaybackRate15 = (value) => {
    //     audioRef.current.playbackRate = 1.5
    //     setPlaybackRateSeleccionada(1.5)
    //     // audioRef.current.play()
    // }
    // const setPlaybackRate2 = (value) => {
    //     audioRef.current.playbackRate = 2
    //     setPlaybackRateSeleccionada(2)
    //     // audioRef.current.play()
    // }

    const columnasLlamadas = [
        { field: 'id', hide: true, headerName: 'ID' },
        { field: 'firstname', headerName: 'Nombre', flex: 1, renderCell: item => { return item.row.firstname + ' ' + item.row.lastname }  },
        { field: 'start_time', headerName: 'Hora inicio', flex: 1 },
        { field: 'end_time', headerName: 'Hora fin', flex: 1 },
        { field: 'total_duration', headerName: 'Duracion', flex: 1 },
        { field: 'direction', headerName: 'Dirección', flex: 1 },
    ]

    const Contenido = () => {
        const [playbackRateSeleccionada, setPlaybackRateSeleccionada] = useState(1)
        const [filaSeleccionada, setFilaSeleccionada] = useState(0)
        const [rowSelectionModel, setRowSelectionModel] = useState()
        const [isPlay, setIsPlay] = useState(false)

        const apiRef = useGridApiRef()

        const handlePlaybackRate = (value) => {
            setPlaybackRateSeleccionada(value)
            audioRef.current.playbackRate = value
        }

        const handleReproducir = () => {

            if( isPlay ) {
                audioRef.current.pause()
            } else {
                audioRef.current.src = llamadas[filaSeleccionada].record
                audioRef.current.playbackRate = playbackRateSeleccionada

                audioRef.current.play()
                apiRef.current.setRowSelectionModel([llamadas[filaSeleccionada].id])
            }
        }

        const handleSiguiente = () => {
            if (filaSeleccionada !== null) {
                const siguienteFila = filaSeleccionada + 1

                if (siguienteFila < llamadas.length) {
                    
                    audioRef.current.src = llamadas[siguienteFila].record
                    audioRef.current.play();
                    audioRef.current.playbackRate = playbackRateSeleccionada

                    setFilaSeleccionada(siguienteFila)

                    apiRef.current.setRowSelectionModel([llamadas[siguienteFila].id])

                }
            }
        }
        const onEnded = () => {
            handleSiguiente()
        }

        return (<>
            <TituloPagina titulo={"Llamadas realizadas"} />
            <Stack direction='column' mb={2} mx={2} spacing={1} alignItems={"center"} style={{maxWidth: "100vh"}}>

                <div id="music_list">
                    <audio ref={audioRef} controls onEnded={ () => onEnded()} onPlay={ () => setIsPlay(true) } onPause={ () => setIsPlay(false) }></audio>
                </div>

                <Stack direction='row' mb={2} mx={2} spacing={1}>
                    <Avatar
                        onClick={ () => handleReproducir() }
                    >
                        { isPlay ? <PauseIcon /> : <PlayArrowIcon /> }
                    </Avatar>
                    <Avatar
                        onClick={ () => handleSiguiente() }
                    >
                        <SkipNextIcon />
                    </Avatar>
                    <Avatar
                        style={{fontSize: 12, backgroundColor: playbackRateSeleccionada === 1 ? grey[900] : ""}}
                        onClick={ () => handlePlaybackRate(1) }
                    >
                        x1
                    </Avatar>
                    <Avatar
                        style={{fontSize: 12, backgroundColor: playbackRateSeleccionada === 1.5 ? grey[900] : ""}}
                        onClick={ () => handlePlaybackRate(1.5) }
                    >
                        x1.5
                    </Avatar>
                    <Avatar
                        style={{fontSize: 12, backgroundColor: playbackRateSeleccionada === 2 ? grey[900] : ""}}
                        onClick={ () => handlePlaybackRate(2) }
                    >
                        x2
                    </Avatar>
                </Stack>

            </Stack>
    
            { llamadas && 
                <div style={{ height: 500, width: '100%', padding:"0 15px" }}>
                <DataGridPro
                    rows={llamadas}
                    columns={columnasLlamadas}
                    apiRef={apiRef}
                    onRowSelectionModelChange={(newRowSelectionModel) => {
                        return setRowSelectionModel(newRowSelectionModel);
                    }}
                    rowSelectionModel={rowSelectionModel}
                    onRowClick={( item => {
                        const index = llamadas.findIndex(llamada => llamada.id === item.row.id)
                        audioRef.current.src = llamadas[index].record
                        audioRef.current.play();
                        audioRef.current.playbackRate = playbackRateSeleccionada
                        setFilaSeleccionada(index)
                    })}
                    
                />
            </div>}
        </>)
    }
    
    /* -------------------------------------------------------------------------- */
    /*                                   Return                                   */
    /* -------------------------------------------------------------------------- */
    return (
        <div className={classes.container}>
            {
                (loading ?
                    
                    <LoadingComponent isFullScreen={true} mensaje={loadingMensaje} />
                    
                    :
                    
                    <Contenido />
                )
            }
        </div>
    )
}
