import { Chip } from '@material-ui/core'
import { amber, blue, green, grey, red } from '@material-ui/core/colors'
import React from 'react'

const StatusTicketsAlumno = ({num_tickets_resueltos, num_tickets_retrasados, num_tickets_pendientes, num_tickets_abiertos}) => {
    return (
        <>
            { num_tickets_resueltos > 0 ? <Chip size="small" style={{backgroundColor: green[500], color: 'white'}} label={`Res: ${num_tickets_resueltos}`} /> : <></> }
            { num_tickets_abiertos > 0 ? <Chip size="small" style={{backgroundColor: red[500], color: 'white'}} label={`Abi: ${num_tickets_abiertos}`} /> : <></> }
            { num_tickets_pendientes > 0 ? <Chip size="small" style={{backgroundColor: blue[500], color: 'white'}} label={`Pen: ${num_tickets_pendientes}`} /> : <></> }
            { num_tickets_retrasados > 0 ? <Chip size="small" style={{backgroundColor: amber[500], color: 'white'}} label={`Ret: ${num_tickets_retrasados}`} /> : <></> }
            { num_tickets_pendientes === 0 && num_tickets_retrasados === 0 ?<Chip size="small" style={{backgroundColor: green[500], color: 'white'}} label={"Al día"} /> : <></> }

            {/* { num_tickets_pendientes > 0 ? <Chip size="small" style={{backgroundColor: blue[500], color: 'white'}} label={`Pendientes: ${num_tickets_pendientes}`} /> : <></> }
            { num_tickets_abiertos > 0 ? <Chip size="small" style={{backgroundColor: red[500], color: 'white'}} label={`Abiertos: ${num_tickets_abiertos}`} /> : <></> } */}
        </>
    )
  
}

export default StatusTicketsAlumno
