import React, { useEffect, useState } from 'react'

import { Box, Button, Card, CardActionArea, CardActions, CardContent, CardHeader, Chip, Dialog, DialogContent, DialogTitle, Grid, TextField, Typography } from '@material-ui/core'

import * as yup from 'yup';
import { useField, useFormik, useFormikContext } from 'formik';
import { useSelector } from 'react-redux';
import { Checkbox, FormControl, FormControlLabel, FormHelperText, InputLabel, MenuItem, Select, Stack } from '@mui/material';
import { Redirect, useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import FaceIcon from '@mui/icons-material/Face';
import { selectUsuarioSeleccionado } from '../../../redux/reducer/loginReducer';
import { getAlumnosRegistradosBuscar, getCreador, getUsuarioFactura, getUsuariosCreadores, insertar_usuario_creador, insertar_usuario_factura, update_usuario_creador, update_usuario_factura } from '../../../services/alumnos';
import Swal from 'sweetalert2';
import DateFnsUtils from '@date-io/date-fns';
import esLocale from 'date-fns/locale/es';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

// const FormObserver = () => {
//     const { values } = useFormikContext() ?? {};
  
//     useEffect(() => {
//       console.log("FormObserver::values", values);
//     }, [values]);
  
//     return null;
//   };

const DialogModalFactura = ( {openModal, setOpenModal, factura_id} ) => {
    const history = useHistory()

    const [alumnos, setAlumnos] = useState([]);
    const [loadling, setLoadling] = useState(false)
    const [factura, setFactura] = useState([])
    const [creadores, setCreadores] = useState([])

    const usuarioSeleccionado = useSelector(state => {
        return selectUsuarioSeleccionado(state);
    });
    
    const [initialValues, setInitialValues] = useState({
        usuario_id: 0,
        concepto: "Colaboración en Curso de Preparación a Oposición para acceso a la función pública",
        cantidad: 1,
        precio: 0,
        fecha: new Date(),
        detalle: "",
    })
    const validationSchema = yup.object({
        
        usuario_id: yup.number().positive('Creador requido').integer().required('Creador requerido'),
        concepto: yup.string().required('Concepto requerido'),
        cantidad: yup.number().required('Cantidad requerida'),
        precio: yup.number().required('Precio requerido'),
        fecha: yup.string().required('Fecha requerido'),
        detalle: yup.string().required('Detalle requerido'),

    });

    useEffect(() => {
      
      getUsuariosCreadores({usuario_id: usuarioSeleccionado.id}).then( response => {
        setCreadores(response.usuarios)
      })

      getUsuarioFactura({usuario_id: usuarioSeleccionado.id, factura_id: factura_id}).then( response => {
        console.log(response)

        if(response.factura) {
          setFactura(response.factura)

          formik.setFieldValue('usuario_id',response.factura.usuario_id)
          formik.setFieldValue('concepto',response.factura.concepto)
          formik.setFieldValue('cantidad',response.factura.cantidad)
          formik.setFieldValue('precio',response.factura.precio)
          formik.setFieldValue('fecha',response.factura.fecha)
          formik.setFieldValue('detalle',response.factura.detalle)

        }
      })
    
    }, [factura_id])

    const formik = useFormik({
      initialValues: initialValues,
      validationSchema: validationSchema,
      onSubmit: (values, actions) => {
        
        if( factura_id === 0 ) {
          insertar_usuario_factura({usuario_id: usuarioSeleccionado.id, campos: values}).then( response => {
            if(!response.factura_id) {
              Swal.fire("No se ha podido insertar el usuario. Correo electrónico duplicado")
            } else {
              
            }
            setOpenModal(false)
          })
        } else {
          values.factura_id = factura_id
          update_usuario_factura({usuario_id: usuarioSeleccionado.id, campos: values}).then( response => {
              Swal.fire("Actualización realizada")
            setOpenModal(false);
          })
        }
        
      },
    })

    return (
      <Dialog
        open={openModal}
        onClose={() => setOpenModal(false)}
        maxWidth={"md"}
      >
        <DialogTitle>Factura</DialogTitle>
        <DialogContent style={{ minHeight: "150px" }}>
          <form
            noValidate
            method="POST"
            enctype="multipart/form-data"
            // autoComplete="off"
            onSubmit={formik.handleSubmit}
          >
            <Grid
              container
              spacing={2}
              style={{ flex: 1, width: "100%", paddingLeft: 15 }}
            >
              <Grid item xs={12}>
                <FormControl
                  variant="outlined"
                  fullWidth
                  style={{ marginTop: 16 }}
                  error={
                    formik.touched.usuario_id &&
                    Boolean(formik.errors.usuario_id)
                  }
                >
                  <InputLabel id="usuario_id-label">Creador</InputLabel>
                  <Select
                    labelId="usuario_id-label"
                    id="usuario_id"
                    name="usuario_id"
                    label="Creador"
                    fullWidth
                    value={formik.values.usuario_id}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.usuario_id &&
                      Boolean(formik.errors.usuario_id)
                    }
                  >
                    
                    {creadores &&
                      creadores.map((item) => {
                        return (
                          <MenuItem key={item.id} value={item.id}>
                            {`${item.nombre} ${item.apellidos}`}
                          </MenuItem>
                        );
                      })}
                  </Select>
                  <FormHelperText>
                    {formik.touched.motivo_baja_id &&
                      formik.errors.motivo_baja_id}
                  </FormHelperText>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={4}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                  <KeyboardDatePicker
                    fullWidth
                    id="fecha"
                    label="Fecha "
                    placeholder="Introduce la fecha"
                    inputVariant="outlined"
                    format="dd/MM/yyyy"
                    value={formik.values.fecha}
                    onChange={(value) => formik.setFieldValue("fecha", value)}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                    error={formik.touched.fecha && Boolean(formik.errors.fecha)}
                    helperText={formik.touched.fecha && formik.errors.fecha}
                  />
                </MuiPickersUtilsProvider>
              </Grid>

              <Grid item xs={12} md={4}>
                <TextField
                  id="cantidad"
                  name="cantidad"
                  label="Cantidad"
                  placeholder="Cantidad"
                  value={formik.values.cantidad}
                  onChange={formik.handleChange}
                  variant="outlined"
                  fullWidth
                  error={
                    formik.touched.cantidad && Boolean(formik.errors.cantidad)
                  }
                  helperText={formik.touched.cantidad && formik.errors.cantidad}
                  // InputLabelProps={{
                  //     shrink: true,
                  // }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  id="precio"
                  name="precio"
                  label="Precio"
                  placeholder="Precio"
                  value={formik.values.precio}
                  onChange={formik.handleChange}
                  variant="outlined"
                  fullWidth
                  error={formik.touched.precio && Boolean(formik.errors.precio)}
                  helperText={formik.touched.precio && formik.errors.precio}
                  // InputLabelProps={{
                  //     shrink: true,
                  // }}
                />
              </Grid>

              <Grid item xs={12} md={12}>
                <TextField
                  id="concepto"
                  name="concepto"
                  label="Concepto"
                  placeholder="Concepto"
                  value={formik.values.concepto}
                  onChange={formik.handleChange}
                  variant="outlined"
                  fullWidth
                  error={
                    formik.touched.concepto && Boolean(formik.errors.concepto)
                  }
                  helperText={formik.touched.concepto && formik.errors.concepto}
                  // InputLabelProps={{
                  //     shrink: true,
                  // }}
                />
              </Grid>

              <Grid item xs={12} md={12}>
                <TextField
                  id="detalle"
                  name="detalle"
                  label="Detalle"
                  placeholder="Detalle"
                  value={formik.values.detalle}
                  onChange={formik.handleChange}
                  variant="outlined"
                  multiline
                  rows={4}
                  fullWidth
                  error={
                    formik.touched.detalle && Boolean(formik.errors.detalle)
                  }
                  helperText={formik.touched.detalle && formik.errors.detalle}
                  // InputLabelProps={{
                  //     shrink: true,
                  // }}
                />
              </Grid>

              <Grid item xs={8} md={2} style={{ marginBottom: 20 }}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  type="submit"
                  size="large"
                  style={{ height: 55 }}
                >
                  {factura_id !== 0 ? "Modificar" : "Insertar"}
                </Button>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    );
}

export default DialogModalFactura
