import React from 'react';
// import "../../assets/css/master.css";

import TemplateDashboard from "../../components/TemplateDashboard";
import TagsContent from './components/TagsContent';

const TagsListado = () => {

    return (
        <>

            <TemplateDashboard>
                <TagsContent />
            </TemplateDashboard>

        </>
    )
};

export default TagsListado;