import React from 'react';
// import "../../assets/css/master.css";

import TemplateDashboard from "../../components/TemplateDashboard";
import AlumnosGestionAcademicaContent from './components/AlumnosGestionAcademicaContent';

const AlumnosGestionAcademicaListado = () => {

    return (
        <>

            <TemplateDashboard>
                <AlumnosGestionAcademicaContent />
            </TemplateDashboard>

        </>
    )
};

export default AlumnosGestionAcademicaListado;