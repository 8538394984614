import React from 'react';

// import AddIcon from '@material-ui/icons/Add';
// import Fab from '@material-ui/core/Fab';
// import { makeStyles} from '@material-ui/core/styles';
// import clsx from 'clsx';
// import {
//     Link,
// } from 'react-router-dom';


// import { SECONDARY_COLOR, SECONDARY_COLOR_HOVER } from '../../constants/colores'

import NavbarTop from "./../NavbarTop";


// import ContentTitle from "./../ContentTitle";

// const useStyles = makeStyles((theme) => ({
//     fab: {
//         position: 'fixed',
//         bottom: theme.spacing(2),
//         right: theme.spacing(2),
//     },
//     fab2: {
//         position: 'fixed',
//         bottom: theme.spacing(2),
//         right: theme.spacing(17),
//     },
//     fabColor: {
//         color: theme.palette.common.white,
//         backgroundColor: SECONDARY_COLOR,
//         '&:hover': {
//             backgroundColor: SECONDARY_COLOR_HOVER,
//         },
//     },
// }));

const TemplateDashboard = (props) => {
    // const { title, showAdd = false, showBack = false } = props;
    const { title, showBack = false } = props;
    // const classes = useStyles();

    return (

        <>

            {/* <!-- navbar navigation component --> */}
            <NavbarTop
                title={title}
                showBack={showBack}
            />
            {/* <!-- end of navbar navigation --> */}

            <div className="wrapper" style={{ marginTop: 60 }}>

                <div id="body" >


                    <div className="content">
                        <div className="container">

                            {/* <ContentTitle pretitle={pretitle} title={title} /> */}

                            {props.children}

                        </div>
                    </div>

                    {/* <div style={{bottom: 10, width: '100%', textAlign: 'center'}}>
                        <img width="450px" src={logos_camara} alt='Innovatiio' />
                    </div> */}
                </div>
            </div>

            {/* {showAdd &&
                <>
                    <Fab aria-label="add" variant="extended"
                    size="small" className={clsx(classes.fab, classes.fabColor)} component={Link} to={`/gastos/add_factura`}>
                        <AddIcon /> Factura
                    </Fab>
                    <Fab aria-label="add" variant="extended"
                    size="small" className={clsx(classes.fab2, classes.fabColor)} component={Link} to={`/gastos/add_manual`}>
                        <AddIcon /> Manual
                    </Fab>
                </>
            } */}
        </>
    );
}

export default TemplateDashboard;