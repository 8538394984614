import React, { useEffect } from 'react'
import { useState } from "react";

import { makeStyles } from '@material-ui/core/styles';

import LoadingComponent from '../../../../components/Loading';

import { useSelector } from "react-redux";

import {
    selectUsuarioSeleccionado,
} from "../../../../redux/reducer/loginReducer";
import EmptyComponent from '../../../../components/EmptyContent';
import TituloPagina from '../../../../components/Titulos/TituloPagina';
import { getAlumnosNotasExamenes, updateAlumnoNotasExamenesPlaza } from '../../../../services/alumnos';
import { DataGridPro, GridToolbarDensitySelector, GridToolbarExport, GridToolbarFilterButton } from '@mui/x-data-grid-pro';
import { Checkbox } from '@material-ui/core';

/* -------------------------------------------------------------------------- */
/*                                   Estilos                                  */
/* -------------------------------------------------------------------------- */
const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: '80px'
    },
    loadingContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "90vh"
    },
    emptyContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "80vh",
        width: '100%',
        flexDirection: 'column',
        textAlign: 'center'
    },
    emptyImage: {
        width: '100%'
    },
    rootFilter: {
        padding: theme.spacing(0.5, 0.5, 0),
        justifyContent: 'space-between',
        display: 'flex',
        alignItems: 'flex-start',
        flexWrap: 'wrap',
      },
    textFieldFilter: {
        [theme.breakpoints.down('xs')]: {
          width: '100%',
        },
        margin: theme.spacing(1, 0.5, 1.5),
        '& .MuiSvgIcon-root': {
          marginRight: theme.spacing(0.5),
        },
        '& .MuiInput-underline:before': {
          borderBottom: `1px solid ${theme.palette.divider}`,
        },
      },
    enlace: {
      color: theme.palette.link.main,
      textDecoration: 'none'
    }
}));

export default function AlumnosNotasContent() {
    const classes = useStyles();

    const [alumnosNotas, setAlumnosNotas] = useState([]);
    const [loading, setLoading] = useState(false)
    const [loadingMensaje, setLoadingMensaje] = useState("")

    const usuarioSeleccionado = useSelector(state => {
        return selectUsuarioSeleccionado(state);
    });

    /* -------------------------------------------------------------------------- */
    /*                                  UseEffect                                 */
    /* -------------------------------------------------------------------------- */
    useEffect(() => {
        setLoading(true)
        setLoadingMensaje("Cargando alumnos")

        const usuario_id = usuarioSeleccionado.id

        getAlumnosNotasExamenes({ usuario_id }).then((response) => {
          setLoading(false)

            response && setAlumnosNotas(response.alumnos_notas)
        });
    }, [])

    /* -------------------------------------------------------------------------- */
    /*                           Definicion de columnas                           */
    /* -------------------------------------------------------------------------- */
    const handleChangePlaza = () => {

    }
    const columnas = 
    [
        { field: 'id', headerName: 'Alumno ID' },
        { field: 'alumno_nombre', headerName: 'Alumno', minWidth: 280, flex: 1 },
        { field: 'telefono', headerName: 'Telefono', minWidth: 280, },
        { field: 'alumno_correo_electronico', headerName: 'Alumno', minWidth: 280, flex: 1 },
        { field: 'nota_tema', headerName: 'Tema', minWidth: 90, type: "number" },
        { field: 'nota_caso_practico', headerName: 'Caso Práctico', minWidth: 100, type: "number" },
        { field: 'nota_cultura_general', headerName: 'Cultura general', minWidth: 100, type: "number" },
        { field: 'nota_programacion', headerName: 'Programación', minWidth: 90, type: "number" },
        { field: 'nota_upd', headerName: 'UPD', minWidth: 90, type: "number" },
        { field: 'nota_global', headerName: 'Global', minWidth: 90, type: "number" },
        { field: 'observaciones', headerName: 'Observaciones', minWidth: 550, flex: 1 },
        { field: 'tutor_nombre', headerName: 'Tutor', minWidth: 150, flex: 1 },
        { field: 'especialidad_nombre', headerName: 'Especialidad', minWidth: 150, flex: 1 },
        { field: 'oposicion_nombre', headerName: 'Oposición', minWidth: 150, flex: 1 },
        { field: 'comunidad_autonoma_nombre', headerName: 'Comunidad', minWidth: 150, flex: 1 },
        { 
            field: 'plaza', 
            headerName: 'Plaza', 
            type: "boolean", 
            minWidth: 150, 
            flex: 1,
            editable: true,
            renderCell: (param) => {
                return param.value === "1" || param.value === true ? "SI" : "NO"
            },
            renderEditCell: (params) => (
                <Checkbox
                    checked={
                        params.value === "1" || params.value === true ? true : false
                    }
                    value={params.value}
                    onChange={(e) => {
                        const updatedValue = e.target.checked;
                        params.api.setEditCellValue({
                            id: params.id,
                            field: "plaza",
                            value: updatedValue,
                        });
                        console.log(params.row.id)
                        updateAlumnoNotasExamenesPlaza({alumno_id: params.row.id, plaza: e.target.checked}).then( response => {
                            console.log(response)
                        })
                        //   CargasService.updateField(
                        //     tableName,
                        //     params.id,
                        //     columnName,
                        //     "boolean",
                        //     updatedValue ? 1 : 0
                        //   );
                        // setUpdateCell(true);
                    }}
                />
              ),
        },
    ]
    ;

    /* -------------------------------------------------------------------------- */
    /*                                  Contenido                                 */
    /* -------------------------------------------------------------------------- */
    function QuickSearchToolbar(props) {
        const classes = useStyles();
      
        return (
          <div className={classes.rootFilter}>
            <div>
              <GridToolbarFilterButton />
              <GridToolbarDensitySelector />
              <GridToolbarExport />
            </div>
            {/* <TextField
              variant="standard"
              value={props.value}
              onChange={props.onChange}
              placeholder="Buscar…"
              className={classes.textFieldFilter}
              InputProps={{
                startAdornment: <SearchIcon fontSize="small" />,
                endAdornment: (
                  <IconButton
                    title="Clear"
                    aria-label="Clear"
                    size="small"
                    style={{ visibility: props.value ? 'visible' : 'hidden' }}
                    onClick={props.clearSearch}
                  >
                    <ClearIcon fontSize="small" />
                  </IconButton>
                ),
              }}
            /> */}
          </div>
        );
      }
      
    //   QuickSearchToolbar.propTypes = {
    //     clearSearch: PropTypes.func.isRequired,
    //     onChange: PropTypes.func.isRequired,
    //     value: PropTypes.string.isRequired,
    //   };

    const Contenido = () => {
        return (
            alumnosNotas ?
                <>
                    <TituloPagina titulo={"Notas de Alumnos"} />

                    <div style={{ height: 500, width: '100%', padding:"0 15px" }}>
                    
                        <DataGridPro
                            rows={alumnosNotas} 
                            columns={columnas} 
                            rowHeight={30}
                            components={{ Toolbar: QuickSearchToolbar }}

                        />
                    </div>
                </>
                
                :
                <EmptyComponent mensaje="No hay datos para mostrar" />
        )
    }
    
    /* -------------------------------------------------------------------------- */
    /*                                   Return                                   */
    /* -------------------------------------------------------------------------- */
    return (
        <div className={classes.container}>
            {
                (loading ?
                    
                    <LoadingComponent isFullScreen={true} mensaje={loadingMensaje} />
                    
                    :
                    
                    <Contenido />
                )
            }
        </div>
    )
}
