import React, { useEffect } from 'react'
import { useState } from "react";

import { makeStyles } from '@material-ui/core/styles';

import LoadingComponent from '../../../../components/Loading';

import { useSelector } from "react-redux";

import {
    selectUsuarioSeleccionado,
} from "../../../../redux/reducer/loginReducer";
import EmptyComponent from '../../../../components/EmptyContent';
// import { deleteInquilino, getAlumnos, inquilinoDelete } from '../../../../services/Alumnos';
import { Box, Button, FormControl, Grid, IconButton, InputLabel, Menu, MenuItem, Select, TextField, Typography } from '@material-ui/core';
import { DataGrid, GridActionsCellItem, GridToolbarDensitySelector, GridToolbarFilterButton} from '@mui/x-data-grid';
import Swal from 'sweetalert2';
import { Link, useHistory } from 'react-router-dom';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import PropTypes from 'prop-types';
import { getAlumnos, getAlumnosBaja, getAlumnosRegistrados, getAlumnosTuteaLastLogin, update_comercial } from '../../../../services/alumnos';
import Moment from 'react-moment';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { BASE_URL_SERVICE, BASE_URL_SERVICE_PROCESOS } from '../../../../constants';

function escapeRegExp(value) {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
  }

// import Moment from 'react-moment';

/* -------------------------------------------------------------------------- */
/*                                   Estilos                                  */
/* -------------------------------------------------------------------------- */
const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: '80px'
    },
    loadingContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "90vh"
    },
    emptyContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "80vh",
        width: '100%',
        flexDirection: 'column',
        textAlign: 'center'
    },
    emptyImage: {
        width: '100%'
    },
    rootFilter: {
        padding: theme.spacing(0.5, 0.5, 0),
        justifyContent: 'space-between',
        display: 'flex',
        alignItems: 'flex-start',
        flexWrap: 'wrap',
      },
    textFieldFilter: {
        [theme.breakpoints.down('xs')]: {
          width: '100%',
        },
        margin: theme.spacing(1, 0.5, 1.5),
        '& .MuiSvgIcon-root': {
          marginRight: theme.spacing(0.5),
        },
        '& .MuiInput-underline:before': {
          borderBottom: `1px solid ${theme.palette.divider}`,
        },
      },
    enlace: {
      color: theme.palette.link.main,
      textDecoration: 'none'
    }
}));

export default function AccesosTuteaContent() {
    const classes = useStyles();

    const [Alumnos, setAlumnos] = useState([]);
    const [loading, setLoading] = useState(false)
    const [loadingMensaje, setLoadingMensaje] = useState("")

    const usuarioSeleccionado = useSelector(state => {
        return selectUsuarioSeleccionado(state);
    });
    const history = useHistory()

    const [selectionModel, setSelectionModel] = React.useState([]);
    const [comercialSeleccionado, setComercialSeleccionado] = React.useState([]);

    const [comerciales, setComerciales] = useState(undefined)

    /* -------------------------------------------------------------------------- */
    /*                                  UseEffect                                 */
    /* -------------------------------------------------------------------------- */
    useEffect(() => {
        setLoading(true)
        setLoadingMensaje("Cargando alumnos")

        const usuario_id = usuarioSeleccionado.id

        getAlumnosTuteaLastLogin({ usuario_id }).then((response) => {

            response && setAlumnos(response.alumnos)
            setLoading(false)

        });

        // getComerciales({usuario_id: usuario_id}).then( (response) => {
        //     response.comerciales && setComerciales(response.comerciales)
        // })
    }, [])

    /* -------------------------------------------------------------------------- */
    /*                           Definicion de columnas                           */
    /* -------------------------------------------------------------------------- */

    const columnas = 
    [
      { field: 'alumno_id', hide: false, headerName: 'ID' },
      { field: 'alumno_nombre', headerName: 'Nombre', minWidth: 150, flex: 1 },
      { field: 'correo_electronico', headerName: 'Correo electronico',minWidth: 120, flex: 1 },
      { field: 'telefono', headerName: 'Teléfono',minWidth: 120 },
      {
        'field': 'alumno_desde', 
        'width':120, 
        'headerName': 'Alumno desde', 
        'description': 'Alumno desde',
        type: 'date',
        renderCell: (cellValues) => {
          return (
            
              (cellValues.value !== null && cellValues.value !== "0000-00-00 00:00:00") ?
      
              <Moment format="DD/MM/YYYY">
                {cellValues.value}
              </Moment>
      
              :
      
              <></>
      
            
          );
        } 
      },
      {
        'field': 'tutea_created', 
        'width':120, 
        'headerName': 'Tutea created', 
        'description': 'Tutea created',
        type: 'date',
        renderCell: (cellValues) => {
          return (
            
              (cellValues.value !== null && cellValues.value !== "0000-00-00 00:00:00") ?
      
              <Moment format="DD/MM/YYYY">
                {cellValues.value}
              </Moment>
      
              :
      
              <></>
      
            
          );
        } 
      },
      {
        'field': 'tutea_last_login', 
        'width':120, 
        'headerName': 'Ultimo acceso', 
        'description': 'Ultimo acceso',
        type: 'date',
        renderCell: (cellValues) => {
          return (
            
              (cellValues.value !== null && cellValues.value !== "0000-00-00 00:00:00") ?
      
              <Moment format="DD/MM/YYYY">
                {cellValues.value}
              </Moment>
      
              :
      
              <></>
      
            
          );
        } 
      },
      { field: 'dias_no_conectado', headerName: 'Dias no conectado',minWidth: 120 },
      {
        'field': 'tutea_ultima_actividad_fecha', 
        'width':120, 
        'headerName': 'Ultimo actividad', 
        'description': 'Ultimo actividad',
        type: 'date',
        renderCell: (cellValues) => {
          return (
            
              (cellValues.value !== null && cellValues.value !== "0000-00-00 00:00:00") ?
      
              <Moment format="DD/MM/YYYY">
                {cellValues.value}
              </Moment>
      
              :
      
              <></>
      
            
          );
        } 
      },
      { field: 'tutea_ultima_actividad_fecha', headerName: 'p',minWidth: 120 },
      { field: 'dias_sin_actividad', headerName: 'Dias sin actividad',minWidth: 120 },
      { field: 'tutea_ultima_actividad_tipo', headerName: 'Ultima actividad tipo',minWidth: 120 },
      { field: 'tutea_ultima_actividad_descripcion', headerName: 'Ultima actividad descripcion',minWidth: 120 },
      { field: 'tutea_tags', headerName: 'Tags tutea',minWidth: 120, flex: 1 },
      { field: 'tutor_nombre', headerName: 'Tutor',minWidth: 120, flex: 1 },
      
      // {
      //   field: 'actions',
      //   type: 'actions',
      //   width: 80,
      //   getActions: (params) => [
      //     <GridActionsCellItem
      //       icon={<EditIcon />}
      //       label="Modificar"
      //       component={Link}
      //       to={`alumnos/detalle/${params.id}`}
      //       showInMenu
      //     />,
      //   ],
      // }
    ]

    /* -------------------------------------------------------------------------- */
    /*                                  Contenido                                 */
    /* -------------------------------------------------------------------------- */
    function QuickSearchToolbar(props) {
        const classes = useStyles();
      
        return (
          <div className={classes.rootFilter}>
            <div>
              <GridToolbarFilterButton />
              <GridToolbarDensitySelector />
            </div>
            <TextField
              variant="standard"
              value={props.value}
              onChange={props.onChange}
              placeholder="Buscar…"
              className={classes.textFieldFilter}
              InputProps={{
                startAdornment: <SearchIcon fontSize="small" />,
                endAdornment: (
                  <IconButton
                    title="Clear"
                    aria-label="Clear"
                    size="small"
                    style={{ visibility: props.value ? 'visible' : 'hidden' }}
                    onClick={props.clearSearch}
                  >
                    <ClearIcon fontSize="small" />
                  </IconButton>
                ),
              }}
            />
          </div>
        );
      }
      
      QuickSearchToolbar.propTypes = {
        clearSearch: PropTypes.func.isRequired,
        onChange: PropTypes.func.isRequired,
        value: PropTypes.string.isRequired,
      };

    const Contenido = () => {
        const [rows, setRows] = React.useState(Alumnos);
        const [searchText, setSearchText] = React.useState('');
        const requestSearch = (searchValue) => {
          // console.log(searchValue)
          setSearchText(searchValue);
          const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
          // const searchRegex = new RegExp(`.*${searchValue}.*`, 'ig');
          const filteredRows = Alumnos.filter((row) => {
              return Object.keys(row).some((field) => {
                  // console.log(field)
                  // console.log(row)
                  return searchRegex.test(row[field]);
              });
          });
          setRows(filteredRows);
        };

        const handleAlumnoDetalle = (row) => {
          console.log(row)
          history.push('alumnos/detalle/' + row.alumno_id )
        }
        const [anchorElOpciones, setAnchorElOpciones] = React.useState(null);
        const open = Boolean(anchorElOpciones);
      
        const handleClickOpciones = (event) => {
          setAnchorElOpciones(event.currentTarget);
        };
      
        const handleCloseOpciones = () => {
          setAnchorElOpciones(null);
        };

        const handleExportarTelefonos = () => {
          setAnchorElOpciones(null);
        }
        return (
            rows ?
                <>
                    
                    <Box mt={12} mb={3} mx={2}>
                        <Typography variant="h5" component="h3" gutterBottom display='inline' style={{fontWeight: 500}} >
                            {"Accesos tutea"}
                        </Typography>

                        <IconButton
                          aria-label="more"
                          aria-controls="long-menu"
                          aria-haspopup="true"
                          onClick={handleClickOpciones}
                        >
                          <MoreVertIcon />
                        </IconButton>

                        <Menu
                          id="long-menu"
                          anchorEl={anchorElOpciones}
                          keepMounted
                          open={open}
                          onClose={handleCloseOpciones}
                          
                        >
                        <MenuItem key="opcion1"  onClick={handleExportarTelefonos}>
                          <a 
                            href={`${BASE_URL_SERVICE_PROCESOS}procesos/actualizar_informacion_tutea`} 
                            target='_blank' 
                            rel='noreferrer'
                            style={{textDecoration: 'none'}}>Sincronizar con tutea</a>
                        </MenuItem>
                       
                      </Menu>
                    </Box>

                    <div style={{ height: 700, width: '100%', padding:"0 15px" }}>
                        <DataGrid 
                            rows={rows} 
                            columns={columnas} 
                            rowHeight={30}
                            components={{ Toolbar: QuickSearchToolbar }}
                            onRowDoubleClick={ (evento ) => handleAlumnoDetalle(evento.row) }
                            componentsProps={{
                                toolbar: {
                                  value: searchText,
                                  onChange: (event) => requestSearch(event.target.value),
                                  clearSearch: () => requestSearch(''),
                                },
                            }}
                        />
                    </div>

                </>
                
                :
                <EmptyComponent mensaje="No hay datos para mostrar" />
        )
    }
    
    /* -------------------------------------------------------------------------- */
    /*                                   Return                                   */
    /* -------------------------------------------------------------------------- */
    return (
        <div className={classes.container}>
            {
                (loading ?
                    
                    <LoadingComponent isFullScreen={true} mensaje={loadingMensaje} />
                    
                    :
                    
                    <Contenido />
                )
            }
        </div>
    )
}
