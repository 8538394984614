import React from 'react';
// import "../../assets/css/master.css";

import TemplateDashboard from "../../../components/TemplateDashboard";
import AlumnosInteraccionContent from './components/AlumnosInteraccionContent';

const AlumnosInteraccion = () => {

    return (
        <>

            <TemplateDashboard>
                <AlumnosInteraccionContent />
            </TemplateDashboard>

        </>
    )
};

export default AlumnosInteraccion;