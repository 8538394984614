import React from 'react';
// import "../../assets/css/master.css";

import TemplateDashboard from "../../../components/TemplateDashboard";
import RingoverEstadisticasContent from './components/RingoverEstadisticasContent';

const RingoverEstadisticasListado = () => {

    return (
        <>

            <TemplateDashboard>
                <RingoverEstadisticasContent />
            </TemplateDashboard>

        </>
    )
};

export default RingoverEstadisticasListado;