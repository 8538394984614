import React, { useEffect } from "react";
import { useState } from "react";

import { makeStyles } from "@material-ui/core/styles";

import LoadingComponent from "../../../components/Loading";
import * as yup from "yup";

import { useSelector } from "react-redux";

import { selectUsuarioSeleccionado } from "../../../redux/reducer/loginReducer";
import TituloPagina from "../../../components/Titulos/TituloPagina";
import {
  deshabilitar_tag,
  getTags,
  insertar_tag,
} from "../../../services/alumnos";
import {
  DataGridPro,
  GridActionsCellItem,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import { useFormik } from "formik";
import { Box, Button, Grid, TextField } from "@material-ui/core";

function escapeRegExp(value) {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
}

// import Moment from 'react-moment';

/* -------------------------------------------------------------------------- */
/*                                   Estilos                                  */
/* -------------------------------------------------------------------------- */
const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: "80px",
  },
  loadingContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "90vh",
  },
  emptyContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "80vh",
    width: "100%",
    flexDirection: "column",
    textAlign: "center",
  },
  emptyImage: {
    width: "100%",
  },
  rootFilter: {
    padding: theme.spacing(0.5, 0.5, 0),
    justifyContent: "space-between",
    display: "flex",
    alignItems: "flex-start",
    flexWrap: "wrap",
  },
  textFieldFilter: {
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
    margin: theme.spacing(1, 0.5, 1.5),
    "& .MuiSvgIcon-root": {
      marginRight: theme.spacing(0.5),
    },
    "& .MuiInput-underline:before": {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
  },
  enlace: {
    color: theme.palette.link.main,
    textDecoration: "none",
  },
}));

export default function TagsContent() {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [loadingMensaje, setLoadingMensaje] = useState("");
  const [tags, setTags] = useState([]);

  // const audioRef= useRef();

  const usuarioSeleccionado = useSelector((state) => {
    return selectUsuarioSeleccionado(state);
  });
  // const history = useHistory()

  /* -------------------------------------------------------------------------- */
  /*                                  UseEffect                                 */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    setLoading(true);
    setLoadingMensaje("Cargando tags");

    const usuario_id = usuarioSeleccionado.id;

    getTags({ usuario_id }).then((response) => {
      setLoading(false);

      response && setTags(response.tags);
    });
  }, []);

  const columnasTags = [
    { field: "id", hide: true, headerName: "ID" },
    { field: "nombre", headerName: "Tag", flex: 1 },
    {
      field: "actions",
      type: "actions",
      width: 80,
      getActions: (params) => [
        <GridActionsCellItem
          label="Deshabilitar tag"
          onClick={(e) => {
            deshabilitar_tag({
              usuario_id: usuarioSeleccionado.id,
              tag_id: params.id,
            }).then((response) => {
              response && setTags(response.tags);
            });
          }}
          showInMenu
        />,
      ],
    },
  ];

  const Contenido = () => {
    // const [filaSeleccionada, setFilaSeleccionada] = useState(0)
    // const [rowSelectionModel, setRowSelectionModel] = useState()

    const apiRef = useGridApiRef();

    const [initialValues, setInitialValues] = useState({
      tag: "",
    });
    const validationSchema = yup.object({
      tag: yup.string().required("Tag es requerido"),
    });
    const formik = useFormik({
      initialValues: initialValues,
      validationSchema: validationSchema,
      onSubmit: (values) => {
        insertar_tag({
          usuario_id: usuarioSeleccionado.id,
          campos: values,
        }).then((response) => {
          console.log(response);
          response && setTags(response.tags);
          formik.setFieldValue("tag", "");
        });
      },
    });

    return (
      <>
        <TituloPagina titulo={"Tags"} />

        <Box style={{ padding: "0 15px" }}>
          <form
            noValidate
            method="POST"
            enctype="multipart/form-data"
            autoComplete="off"
            onSubmit={formik.handleSubmit}
          >
            <Grid container spacing={2}>
              <Grid item xs={10} md={10}>
                <TextField
                  id="tag"
                  name="tag"
                  label="Tag"
                  placeholder="Añade una tag"
                  fullWidth
                  margin="normal"
                  value={formik.values.tag}
                  onChange={formik.handleChange}
                  variant="outlined"
                  error={formik.touched.tag && Boolean(formik.errors.tag)}
                  helperText={formik.touched.tag && formik.errors.tag}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>

              <Grid item xs={12} md={2}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  type="submit"
                  size="large"
                  style={{ marginTop: "16px" }}
                >
                  Guardar
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
        {tags && (
          <div style={{ height: 500, width: "100%", padding: "0 15px" }}>
            <DataGridPro
              rows={tags}
              columns={columnasTags}
              apiRef={apiRef}
              // onRowSelectionModelChange={(newRowSelectionModel) => {
              //     return setRowSelectionModel(newRowSelectionModel);
              // }}
              // rowSelectionModel={rowSelectionModel}
              // onRowClick={( item => {
              //     const index = llamadas.findIndex(llamada => llamada.id === item.row.id)
              //     audioRef.current.src = llamadas[index].record
              //     audioRef.current.play();
              //     audioRef.current.playbackRate = playbackRateSeleccionada
              //     setFilaSeleccionada(index)
              // })}
            />
          </div>
        )}
      </>
    );
  };

  /* -------------------------------------------------------------------------- */
  /*                                   Return                                   */
  /* -------------------------------------------------------------------------- */
  return (
    <div className={classes.container}>
      {loading ? (
        <LoadingComponent isFullScreen={true} mensaje={loadingMensaje} />
      ) : (
        <Contenido />
      )}
    </div>
  );
}
