import React from 'react';
// import "../../assets/css/master.css";

import TemplateDashboard from "../../../components/TemplateDashboard";
import AlumnosPosibleBajaContent from './components/AlumnosPosibleBajaContent';

const AlumnosPosibleBajaListado = () => {

    return (
        <>

            <TemplateDashboard>
                <AlumnosPosibleBajaContent />
            </TemplateDashboard>

        </>
    )
};

export default AlumnosPosibleBajaListado;