import React, { useEffect, useState } from 'react'

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Paper, TextField } from '@material-ui/core'

import * as yup from 'yup';
import { useFormik } from 'formik';
import { useSelector } from 'react-redux';
import {  useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { selectUsuarioSeleccionado } from '../../../redux/reducer/loginReducer';
import { getCreador, insertar_usuario_creador, update_usuario_creador } from '../../../services/alumnos';
import Swal from 'sweetalert2';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { getTutoresPeriodosLiquidacion } from '../../../services/tutores';
import axios from 'axios';
import { BASE_URL_SERVICE } from '../../../constants';

// const FormObserver = () => {
//     const { values } = useFormikContext() ?? {};
  
//     useEffect(() => {
//       console.log("FormObserver::values", values);
//     }, [values]);
  
//     return null;
//   };

const DialogModalGenerarExcelLiquidaciones = ( {openModal, setOpenModal} ) => {
    // const [creador, setCreador] = useState([])
    const [periodos, setPeriodos] = useState([]);
    const [periodosSeleccionados, setPeriodosSeleccionados] = useState([])

    const usuarioSeleccionado = useSelector(state => {
        return selectUsuarioSeleccionado(state);
    });
    
    // const [initialValues, setInitialValues] = useState({
    //     correo_electronico: "",
    //     nombre: "",
    //     apellidos: "",
    //     telefono: "",
    //     dni: "",
    //     direccion: "",
    //     provincia: "",
    //     num_cuenta: "",
    //     correo_electronico_personal: "",
    // })
    // const validationSchema = yup.object({
        
    //     correo_electronico: yup.string().required('Correo electronico requerido'),
    //     nombre: yup.string().required('Nombre requerido'),
    //     apellidos: yup.string().required('Apellidos requeridos'),
    //     telefono: yup.string().required('Teléfono requerido'),
    //     dni: yup.string().required('DNI requerido'),
    //     direccion: yup.string().required('Dirección requerida'),
    //     provincia: yup.string().required('Provincia requerida'),
    //     num_cuenta: yup.string().required('Numero de cuenta requerida'),
    //     correo_electronico_personal: yup.string().required('Correo electrónico personal requerido'),

    // });

    useEffect(() => {
      
    //   getCreador({usuario_id: creador_id}).then( response => {
    //     console.log(response)

    //     if(response.creador) {
    //       setCreador(response.creador)

    //       formik.setFieldValue('correo_electronico',response.creador.mail)
    //       formik.setFieldValue('nombre',response.creador.nombre)
    //       formik.setFieldValue('apellidos',response.creador.apellidos)
    //       formik.setFieldValue('telefono',response.creador.telefono)
    //       formik.setFieldValue('dni',response.creador.dni)
    //       formik.setFieldValue('direccion',response.creador.direccion)
    //       formik.setFieldValue('provincia',response.creador.provincia)
    //       formik.setFieldValue('num_cuenta',response.creador.num_cuenta)
    //       formik.setFieldValue('correo_electronico_personal',response.creador.correo_electronico_personal)
    //     }
    //   })
    getTutoresPeriodosLiquidacion({usuario_id: usuarioSeleccionado.id}).then( (response) => {
      response && setPeriodos(response.periodos)
    })

    }, [])

    // const formik = useFormik({
    //   initialValues: initialValues,
    //   validationSchema: validationSchema,
    //   onSubmit: (values, actions) => {
        
    //     if( creador_id === 0 ) {
    //       insertar_usuario_creador({usuario_id: usuarioSeleccionado.id, campos: values}).then( response => {
    //         console.log(response)
    //         if(!response.usuario_id) {
    //           Swal.fire("No se ha podido insertar el usuario. Correo electrónico duplicado")
    //         } else {
              
    //         }
    //         setOpenModal(false)
    //       })
    //     } else {
    //       values.creador_id = creador_id
    //       update_usuario_creador({usuario_id: usuarioSeleccionado.id, campos: values}).then( response => {
    //           Swal.fire("Actualización realizada")
    //         setOpenModal(false);
    //       })
    //     }
        
    //     // if(values.telefono === '' && values.correo_electronico === '' ) {
    //     //   actions.setFieldError('telefono', 'Telefono o correo electrónico obligatorios')
    //     //   actions.setFieldError('correo_electronico', 'Telefono o correo electrónico obligatorios')
    //     //   return;
    //     // }

    //     // getAlumnosRegistradosBuscar({usuario_id: usuarioSeleccionado.id, telefono: values.telefono, correo_electronico: values.correo_electronico}).then( (response) => {
          
    //     //     setAlumnos(response.alumnos)
    //     //     setLoadling(false)

    //     // } )  

    //   },
    // })

    const columnasPeriodos = [
      // { field: 'id', hide: true, headerName: 'ID' },
      { 
        field: 'periodo', 
        headerName: 'Periodo',
        renderCell: ( cellValues => {
          return `${cellValues.row.mes}/${cellValues.row.anyo}`
        } ),
        flex: true
      },
  ];

  const handleGenerarExcelRemesas = () => {

    axios({
      method: 'get',
      url: BASE_URL_SERVICE + 'alumno/generar_excel_remesas_creadores',
      params: { periodos: periodosSeleccionados },
      responseType: 'blob', // Important
      headers: {
       'Content-Type': 'application/octet-stream'
      },
   }).then( (response) => {
    const path= window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = path;
      link.setAttribute('download', 'remesa.xlsx');
      document.body.appendChild(link);
      link.click();
   })

  }

    return (
      <Dialog
        open={openModal}
        onClose={() => setOpenModal(false)}
        maxWidth={"md"}
      >
        <DialogTitle>Selecciona un periodo</DialogTitle>
        <DialogContent style={{ minHeight: "450px", minWidth: 400 }}>
          <div style={{ height: 500, width: "100%" }}>
            <DataGridPro
              rows={periodos}
              columns={columnasPeriodos}
              checkboxSelection
              onRowSelectionModelChange={(newSelectionModel) => {
                setPeriodosSeleccionados(newSelectionModel);
              }}
            />
          </div>
        </DialogContent>
        <DialogActions style={{ padding: "10px 22px 20px" }}>
          <Button variant="outlined" color="primary" onClick={() => handleGenerarExcelRemesas()}>
            Generar Excel remesas (Selecciona al menos un periodo)
          </Button>
        </DialogActions>
      </Dialog>
    );
}

export default DialogModalGenerarExcelLiquidaciones
