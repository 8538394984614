import React from 'react';
// import "../../assets/css/master.css";

import TemplateDashboard from "../../components/TemplateDashboard";
import TutoresContent from './components/TutoriasIndividualesContent';
import TutoriasIndividualesContent from './components/TutoriasIndividualesContent';

const TutoriasIndividualesListado = () => {

    return (
        <>

            <TemplateDashboard>
                <TutoriasIndividualesContent />
            </TemplateDashboard>

        </>
    )
};

export default TutoriasIndividualesListado;