import React from 'react';
// import "../../assets/css/master.css";

import TemplateDashboard from "../../components/TemplateDashboard";
import LiquidacionTutoresContent from './components/LiquidacionTutoresContent';

const LiquidacionTutores = () => {

    return (
        <>

            <TemplateDashboard>
                <LiquidacionTutoresContent />
            </TemplateDashboard>

        </>
    )
};

export default LiquidacionTutores;