import React, { useEffect } from "react";
import { useState } from "react";

import { useSelector } from "react-redux";

import { selectUsuarioSeleccionado } from "../../../redux/reducer/loginReducer";
import {
  getPlanificacionLlamadas,
} from "../../../services/alumnos";
import {
  DataGridPro,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-pro";
import {
  Box,
  Divider,
  Grid,
  Link,
  Paper,
  Typography,
} from "@material-ui/core";
import useDataGridFilterPersist from "../../../hooks/useDataGridFilterPersist";
import moment from 'moment/moment';
import Moment from 'react-moment';

/* -------------------------------------------------------------------------- */
/*                                   Estilos                                  */
/* -------------------------------------------------------------------------- */
export default function PlanificacionLlamadasContent() {
  // const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [loadingMensaje, setLoadingMensaje] = useState("");
  const [leadsComentarios, setLeadsComentarios] = useState([]);
  const [leadsPrimariaInfantil, setLeadsPrimariaInfantil] = useState([]);
  const [leadsSecundaria, setLeadsSecundaria] = useState([]);
  const [leadsValidos, setLeadsValidos] = useState([]);
  const [leadsContactadosHoy, setLeadsContactadosHoy] = useState([]);
  const [leadsPlanificados, setLeadsPlanificados] = useState([]);
  const [leadsCurso, setLeadsCurso] = useState([]);
  const [llamadasMasMinuto, setLlamadasMasMinuto] = useState([]);
  const [llamadasMasTresMinutos, setLlamadasMasTresMinutos] = useState([]);
  
  // // const audioRef= useRef();

  const usuarioSeleccionado = useSelector((state) => {
    return selectUsuarioSeleccionado(state);
  });
  // const history = useHistory()

  const CardContenido = ({ titulo, children }) => {
    return (
      <Paper elevation={1} style={{ padding: 30 }}>
        <Typography variant="h5" style={{ paddingBottom: 5 }}>
          {titulo}
        </Typography>
        <Divider />
        <Box style={{ paddingTop: 20 }}>{children}</Box>
      </Paper>
    );
  };
  const CardIndicador = ({ titulo, descripcion, valor }) => {
    return (
      <Paper elevation={1} style={{ padding: 30, display: 'flex', justifyContent: 'space-between' }}>
        <div>
          <Typography variant="h5" style={{ paddingBottom: 5 }}>
            {titulo}
          </Typography>
          <Typography variant="body1" style={{ paddingBottom: 5 }}>
            {descripcion}
          </Typography>
        </div>
        <Typography variant="h3" style={{ paddingBottom: 5, fontWeight: 'bolder' }}>
          {valor}
        </Typography>
      </Paper>
    );
  };

  const columnasLeadsComentarios = [
    { field: "id", headerName: "ID" },
    { field: "nombre", headerName: "Nombre", flex: 1, minWidth: 300, renderCell: (item) => {
      return <Link href={`/alumnos/detalle/${item.row.id}`}>{item.row.nombre}</Link>
    } },
    { field: "comunidad_autonoma_nombre", headerName: "Comunidad", width: 200 },
    { field: "oposicion_nombre", headerName: "Oposicion", width: 150 },
    { field: "especialidad_nombre", headerName: "Especialidad", width: 350 },
    { field: "status_nombre", headerName: "Estado", width: 200 },
    // { field: "es_lead_valido", headerName: "Valido", width: 100,renderCell: (item) => {
    //   return item.row.es_lead_valido === "1" ? 'SI' : 'NO'
    // } },
    {
      'field': 'created', 
      'width':200, 
      'headerName': 'Añadido', 
      'description': 'Añadido',
      type: 'date',
      valueFormatter: params => {
        return moment(params?.value).format("DD/MM/YYYY hh:mm A")
      },
      renderCell: (cellValues) => {
        return (
          
            (cellValues.value !== null && cellValues.value !== "0000-00-00 00:00:00") ?
    
            <Moment format="DD/MM/YYYY HH:mm:ss">
              {cellValues.value}
            </Moment>
    
            :
    
            <></>
    
          
        );
      } 
  },
  ]
  const columnasLeadsPrimariaInfantil = [
    { field: "id", headerName: "ID" },
    { field: "nombre", headerName: "Nombre", flex: 1, minWidth: 300, renderCell: (item) => {
      return <Link href={`/alumnos/detalle/${item.row.id}`}>{item.row.nombre}</Link>
    } },
    { field: "comunidad_autonoma_nombre", headerName: "Comunidad", width: 200 },
    { field: "especialidad_nombre", headerName: "Especialidad", width: 350 },
    // { field: "status_nombre", headerName: "Estado", width: 100 },
    { field: "es_lead_valido", headerName: "Valido", width: 100,renderCell: (item) => {
      return item.row.es_lead_valido === "1" ? 'SI' : 'NO'
    } },
    { field: "oposicion_nombre", headerName: "Oposicion", width: 150 },
    {
      'field': 'created', 
      'width':200, 
      'headerName': 'Añadido', 
      'description': 'Añadido',
      type: 'date',
      valueFormatter: params => {
        return moment(params?.value).format("DD/MM/YYYY hh:mm A")
      },
      renderCell: (cellValues) => {
        return (
          
            (cellValues.value !== null && cellValues.value !== "0000-00-00 00:00:00") ?
    
            <Moment format="DD/MM/YYYY HH:mm:ss">
              {cellValues.value}
            </Moment>
    
            :
    
            <></>
    
          
        );
      } 
  },
  ]
  const columnasLeadsPlanificados = [
    { field: "id", headerName: "ID" },
    { field: "nombre", headerName: "Nombre", flex: 1, minWidth: 300, renderCell: (item) => {
      return <Link href={`/alumnos/detalle/${item.row.id}`}>{item.row.nombre}</Link>
    } },
    {
      'field': 'proximo_contacto', 
      'width':200, 
      'headerName': 'Proximo contacto', 
      'description': 'Proximo contacto',
      type: 'date',
      valueFormatter: params => {
        return moment(params?.value).format("DD/MM/YYYY hh:mm A")
      },
      renderCell: (cellValues) => {
        return (
          
            (cellValues.value !== null && cellValues.value !== "0000-00-00 00:00:00") ?
    
            <Moment format="DD/MM/YYYY HH:mm:ss">
              {cellValues.value}
            </Moment>
    
            :
    
            <></>
    
          
        );
      } 
    },
    { field: "comunidad_autonoma_nombre", headerName: "Comunidad", width: 200 },
    { field: "especialidad_nombre", headerName: "Especialidad", width: 350 },
    { field: "status_nombre", headerName: "Estado", width: 200 },
    { field: "temperatura_nombre", headerName: "Temperatura", width: 200 },
    // { field: "es_lead_valido", headerName: "Valido", width: 100,renderCell: (item) => {
    //   return item.row.es_lead_valido === "1" ? 'SI' : 'NO'
    // } },
    { field: "oposicion_nombre", headerName: "Oposicion", width: 150 },
    {
      'field': 'created', 
      'width':200, 
      'headerName': 'Añadido', 
      'description': 'Añadido',
      type: 'date',
      valueFormatter: params => {
        return moment(params?.value).format("DD/MM/YYYY hh:mm A")
      },
      renderCell: (cellValues) => {
        return (
          
            (cellValues.value !== null && cellValues.value !== "0000-00-00 00:00:00") ?
    
            <Moment format="DD/MM/YYYY HH:mm:ss">
              {cellValues.value}
            </Moment>
    
            :
    
            <></>
    
          
        );
      } ,
  },
  
  ]
  const columnasLeadsSecundaria = [
    { field: "id", headerName: "ID" },
    { field: "nombre", headerName: "Nombre", flex: 1, minWidth: 300, renderCell: (item) => {
      return <Link href={`/alumnos/detalle/${item.row.id}`}>{item.row.nombre}</Link>
    } },
    { field: "comunidad_autonoma_nombre", headerName: "Comunidad", width: 200 },
    { field: "especialidad_nombre", headerName: "Especialidad", width: 350 },
    // { field: "status_nombre", headerName: "Estado", width: 100 },
    { field: "es_lead_valido", headerName: "Valido", width: 100,renderCell: (item) => {
      return item.row.es_lead_valido === "1" ? 'SI' : 'NO'
    } },
    {
      'field': 'created', 
      'width':200, 
      'headerName': 'Añadido', 
      'description': 'Añadido',
      type: 'date',
      valueFormatter: params => {
        return moment(params?.value).format("DD/MM/YYYY hh:mm A")
      },
      renderCell: (cellValues) => {
        return (
          
            (cellValues.value !== null && cellValues.value !== "0000-00-00 00:00:00") ?
    
            <Moment format="DD/MM/YYYY HH:mm:ss">
              {cellValues.value}
            </Moment>
    
            :
    
            <></>
    
          
        );
      } 
  },
  ]
  const columnasLeadsValidos = [
    { field: "id", headerName: "ID" },
    { field: "nombre", headerName: "Nombre", flex: 1, minWidth: 300, renderCell: (item) => {
      return <Link href={`/alumnos/detalle/${item.row.id}`}>{item.row.nombre}</Link>
    } },
    { field: "comunidad_autonoma_nombre", headerName: "Comunidad", width: 200 },
    { field: "especialidad_nombre", headerName: "Especialidad", width: 350 },
    { field: "status_nombre", headerName: "Estado", width: 100 },
    { field: "lead_valido_segundo_nivel", headerName: "Estado V2", width: 100 },
    {
      'field': 'created', 
      'width':200, 
      'headerName': 'Añadido', 
      'description': 'Añadido',
      type: 'date',
      valueFormatter: params => {
        return moment(params?.value).format("DD/MM/YYYY hh:mm A")
      },
      renderCell: (cellValues) => {
        return (
          
            (cellValues.value !== null && cellValues.value !== "0000-00-00 00:00:00") ?
    
            <Moment format="DD/MM/YYYY HH:mm:ss">
              {cellValues.value}
            </Moment>
    
            :
    
            <></>
    
          
        );
      } 
  },
  ]
  const columnasLeadsCurso = [
    { field: "id", headerName: "ID" },
    { field: "nombre", headerName: "Nombre", flex: 1, minWidth: 300, renderCell: (item) => {
      return <Link href={`/alumnos/detalle/${item.row.id}`}>{item.row.nombre}</Link>
    } },
    { field: "comunidad_autonoma_nombre", headerName: "Comunidad", width: 200 },
    { field: "especialidad_nombre", headerName: "Especialidad", width: 350 },
    { field: "status_nombre", headerName: "Estado", width: 100 },
    { field: "lead_valido_segundo_nivel", headerName: "Estado V2", width: 100 },
    {
      'field': 'created', 
      'width':200, 
      'headerName': 'Añadido', 
      'description': 'Añadido',
      type: 'date',
      valueFormatter: params => {
        return moment(params?.value).format("DD/MM/YYYY hh:mm A")
      },
      renderCell: (cellValues) => {
        return (
          
            (cellValues.value !== null && cellValues.value !== "0000-00-00 00:00:00") ?
    
            <Moment format="DD/MM/YYYY HH:mm:ss">
              {cellValues.value}
            </Moment>
    
            :
    
            <></>
    
          
        );
      } 
  },
  ]
  /* -------------------------------------------------------------------------- */
  /*                                  UseEffect                                 */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    setLoading(true);
    setLoadingMensaje("Cargando");

    const usuario_id = usuarioSeleccionado.id;

    getPlanificacionLlamadas({
      usuario_id: usuario_id,
      nombre_dato: "leads_comentarios",
    }).then((response) => {
      setLoading(false);

      response && setLeadsComentarios(response.resultado);
    });
    
    getPlanificacionLlamadas({
      usuario_id: usuario_id,
      nombre_dato: "leads_primaria_infantil",
    }).then((response) => {
      setLoading(false);

      response && setLeadsPrimariaInfantil(response.resultado);
    });
    
    getPlanificacionLlamadas({
      usuario_id: usuario_id,
      nombre_dato: "leads_secundaria",
    }).then((response) => {
      setLoading(false);

      response && setLeadsSecundaria(response.resultado);
    });
    
    getPlanificacionLlamadas({
      usuario_id: usuario_id,
      nombre_dato: "leads_validos",
    }).then((response) => {
      setLoading(false);

      response && setLeadsValidos(response.resultado);
    });
    
    getPlanificacionLlamadas({
      usuario_id: usuario_id,
      nombre_dato: "leads_contactados_hoy",
    }).then((response) => {
      setLoading(false);

      response && setLeadsContactadosHoy(response.resultado);
    });
    
    getPlanificacionLlamadas({
      usuario_id: usuario_id,
      nombre_dato: "leads_planificados",
    }).then((response) => {
      setLoading(false);

      response && setLeadsPlanificados(response.resultado);
    });

    getPlanificacionLlamadas({
      usuario_id: usuario_id,
      nombre_dato: "llamadas_mas_minuto",
    }).then((response) => {
      setLoading(false);

      response && setLlamadasMasMinuto(response.resultado);
    });
    
    getPlanificacionLlamadas({
      usuario_id: usuario_id,
      nombre_dato: "llamadas_mas_tres_minutos",
    }).then((response) => {
      setLoading(false);

      response && setLlamadasMasTresMinutos(response.resultado);
    });
    
    getPlanificacionLlamadas({
      usuario_id: usuario_id,
      nombre_dato: "leads_curso",
    }).then((response) => {
      setLoading(false);

      response && setLeadsCurso(response.resultado);
    });
  }, []);

  const Contenido = () => {
    const [filterModelLeadsComentarios, onFilterModelChangeLeadsComentarios] = useDataGridFilterPersist(0,'planificacion_llamadas_page','leads_recibidos_comentarios')
    const [filterModelLeadsPrimariaInfantil, onFilterModelChangeLeadsPrimariaInfantil] = useDataGridFilterPersist(0,'planificacion_llamadas_page','leads_recibidos_primaria_infantil')
    const [filterModelSecundaria, onFilterModelChangeSecundaria] = useDataGridFilterPersist(0,'planificacion_llamadas_page','leads_recibidos_secundaria')
    const [filterModelValidos, onFilterModelChangeValidos] = useDataGridFilterPersist(0,'planificacion_llamadas_page','leads_recibidos_validos')
    const [filterModelPlanificados, onFilterModelChangePlanificados] = useDataGridFilterPersist(0,'planificacion_llamadas_page','leads_planificados')
    const [filterModelCurso, onFilterModelChangeCurso] = useDataGridFilterPersist(0,'planificacion_llamadas_page','leads_curso')

    return (
      <>
        <Box style={{ marginTop: 85, padding: "0 15px" }}>
          <Grid container spacing={2}>
            {/* <Grid item xs={12} md={6}>
              <CardContenido titulo="Próxima llamada recomendada">
                Contiene la siguiente llamada que recomienda el sistema
              </CardContenido>
            </Grid> */}
            <Grid item xs={12} md={4}>
              <CardIndicador 
                titulo="Leads contactados hoy" 
                descripcion="Leads en los que se han puesto notas"
                valor={leadsContactadosHoy} 
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <CardIndicador 
                titulo="Llamadas de mas de un minuto" 
                descripcion="Llamadas de Ringover de mas de un minuto"
                valor={llamadasMasMinuto} 
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <CardIndicador 
                titulo="Llamadas de mas de tres minutos" 
                descripcion="Llamadas de Ringover de mas de tres minutos"
                valor={llamadasMasTresMinutos} 
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <CardContenido titulo="Leads calientes">
                <Box style={{marginBottom: 20}}>
                  Contiene los leads que han entrado con alguna duda al sistema. (Leads o repetidos que han escrito comentarios o dudas)
                </Box>
                <Box>
                  <div
                    style={{ height: 400, width: "100%", padding: "0" }}
                  >
                    <DataGridPro
                      rows={leadsComentarios}
                      columns={columnasLeadsComentarios}
                      components={{ Toolbar: () => { 
                          return <div style={{display: 'flex', padding: '0.5px 0.5px',}}>
                          <GridToolbarFilterButton />
                        </div> 
                      }}}
                      filterModel={ filterModelLeadsComentarios }
                      onFilterModelChange={ onFilterModelChangeLeadsComentarios }
                    />

                  </div>
                </Box>
              </CardContenido>
            </Grid>
            <Grid item xs={12} md={6}>
              <CardContenido titulo="Leads planificados">
                <Box style={{marginBottom: 20}}>
                  Leads con próximo contacto en este mes
                </Box>
                <Box>
                  <div
                    style={{ height: 400, width: "100%", padding: "0" }}
                  >
                    <DataGridPro
                      rows={leadsPlanificados}
                      columns={columnasLeadsPlanificados}
                      components={{ Toolbar: () => { 
                          return <div style={{display: 'flex', padding: '0.5px 0.5px',}}>
                          <GridToolbarFilterButton />
                        </div> 
                      }}}
                      filterModel={ filterModelPlanificados }
                      onFilterModelChange={ onFilterModelChangePlanificados }
                    />

                  </div>
                </Box>
              </CardContenido>
            </Grid>
            <Grid item xs={12} md={6}>
              <CardContenido titulo="Leads válidos">
              <Box style={{marginBottom: 20}}>
                  Contiene los leads válidos
                </Box>
                <Box>
                <div
                    style={{ height: 400, width: "100%", padding: "0" }}
                  >
                    <DataGridPro
                      rows={leadsValidos}
                      columns={columnasLeadsValidos}
                      components={{ Toolbar: () => { 
                          return <div style={{display: 'flex', padding: '0.5px 0.5px',}}>
                          <GridToolbarFilterButton />
                        </div> 
                      }}}
                      filterModel={ filterModelValidos }
                      onFilterModelChange={ onFilterModelChangeValidos }
                    />
                  </div>
                  </Box>
              </CardContenido>
            </Grid>
            <Grid item xs={12} md={6}>
              <CardContenido titulo="Leads curso">
              <Box style={{marginBottom: 20}}>
                  Contiene los leads de curso
                </Box>
                <Box>
                <div
                    style={{ height: 400, width: "100%", padding: "0" }}
                  >
                    <DataGridPro
                      rows={leadsCurso}
                      columns={columnasLeadsCurso}
                      components={{ Toolbar: () => { 
                          return <div style={{display: 'flex', padding: '0.5px 0.5px',}}>
                          <GridToolbarFilterButton />
                        </div> 
                      }}}
                      filterModel={ filterModelCurso }
                      onFilterModelChange={ onFilterModelChangeCurso }
                    />
                  </div>
                  </Box>
              </CardContenido>
            </Grid>
            <Grid item xs={12} md={6}>
              <CardContenido titulo="Leads de primaria e infantil">
              <div
                    style={{ height: 400, width: "100%", padding: "0" }}
                  >
                    <DataGridPro
                      rows={leadsPrimariaInfantil}
                      columns={columnasLeadsPrimariaInfantil}
                      components={{ Toolbar: () => { 
                          return <div style={{display: 'flex', padding: '0.5px 0.5px',}}>
                          <GridToolbarFilterButton />
                        </div> 
                      }}}
                      filterModel={ filterModelLeadsPrimariaInfantil }
                      onFilterModelChange={ onFilterModelChangeLeadsPrimariaInfantil }
                    />

                  </div>
              </CardContenido>
            </Grid>
            <Grid item xs={12} md={6}>
              <CardContenido titulo="Leads de secundaria">
              <div
                    style={{ height: 400, width: "100%", padding: "0" }}
                  >
                    <DataGridPro
                      rows={leadsSecundaria}
                      columns={columnasLeadsSecundaria}
                      components={{ Toolbar: () => { 
                          return <div style={{display: 'flex', padding: '0.5px 0.5px',}}>
                          <GridToolbarFilterButton />
                        </div> 
                      }}}
                      filterModel={ filterModelSecundaria }
                      onFilterModelChange={ onFilterModelChangeSecundaria }
                    />
                  </div>
              </CardContenido>
            </Grid>
            
          </Grid>
        </Box>

      </>
    );
  };

  /* -------------------------------------------------------------------------- */
  /*                                   Return                                   */
  /* -------------------------------------------------------------------------- */
  return (
    <Contenido />
  );
}
