import React, { useEffect } from 'react'
import { useState } from "react";

import { makeStyles } from '@material-ui/core/styles';

import LoadingComponent from '../../../components/Loading';

import { useSelector } from "react-redux";

import {
    selectUsuarioSeleccionado,
} from "../../../redux/reducer/loginReducer";
import EmptyComponent from '../../../components/EmptyContent';
// import { deleteInquilino, getAlumnos, inquilinoDelete } from '../../../../services/Alumnos';
import { Box, Button, FormControl, Grid, IconButton, InputLabel, Menu, MenuItem, Select, TextField, Typography } from '@material-ui/core';
import { DataGrid, GridActionsCellItem, GridToolbarDensitySelector, GridToolbarFilterButton} from '@mui/x-data-grid';
import EditIcon from '@material-ui/icons/Edit';
import SyncIcon from '@material-ui/icons/Sync';
import Swal from 'sweetalert2';
import { Link, useHistory } from 'react-router-dom';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import PropTypes from 'prop-types';
import TituloPagina from '../../../components/Titulos/TituloPagina';
import AddIcon from '@material-ui/icons/Add';
import { delete_usuario_factura, getAlumnos, getAlumnosBaja, getAlumnosPosibleBaja, getAlumnosRegistrados, getTutoresGestion, getUsuariosCreadores, getUsuariosFacturas, update_comercial } from '../../../services/alumnos';
import { Stack } from '@mui/system';
import {sincronizar_formularios, sincronizar_notion, sincronizar_woo_order} from '../../../services/sincronizar'
import { getComerciales } from '../../../services/comerciales';
import Moment from 'react-moment';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { BASE_URL_SERVICE } from '../../../constants';
import DialogModalCreador from './DialogModalCreador';
import DialogModalFactura from './DialogModalFactura';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import DialogModalGenerarExcelLiquidaciones from './DialogModalGenerarExcelLiquidaciones';
import { facturaUsuarioUpdateCampoValor } from '../../../services/tutores';

function escapeRegExp(value) {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
  }

// import Moment from 'react-moment';

/* -------------------------------------------------------------------------- */
/*                                   Estilos                                  */
/* -------------------------------------------------------------------------- */
const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: '80px'
    },
    loadingContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "90vh"
    },
    emptyContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "80vh",
        width: '100%',
        flexDirection: 'column',
        textAlign: 'center'
    },
    emptyImage: {
        width: '100%'
    },
    rootFilter: {
        padding: theme.spacing(0.5, 0.5, 0),
        justifyContent: 'space-between',
        display: 'flex',
        alignItems: 'flex-start',
        flexWrap: 'wrap',
      },
    textFieldFilter: {
        [theme.breakpoints.down('xs')]: {
          width: '100%',
        },
        margin: theme.spacing(1, 0.5, 1.5),
        '& .MuiSvgIcon-root': {
          marginRight: theme.spacing(0.5),
        },
        '& .MuiInput-underline:before': {
          borderBottom: `1px solid ${theme.palette.divider}`,
        },
      },
    enlace: {
      color: theme.palette.link.main,
      textDecoration: 'none'
    }
}));

export default function FacturacionCreadoresContent() {
    const classes = useStyles();

    const [facturas, setFacturas] = useState([]);
    const [loading, setLoading] = useState(false)
    const [loadingMensaje, setLoadingMensaje] = useState("")

    const usuarioSeleccionado = useSelector(state => {
        return selectUsuarioSeleccionado(state);
    });
    const [openModalCreadores, setOpenModalCreadores] = useState(false)
    const [openModalFactura, setOpenModalFactura] = useState(false)
    const [openModalGenerarExcelLiquidaciones, setOpenModalGenerarExcelLiquidaciones] = useState(false)

    const [usuarioCreadorIdSeleccionado, setUsuarioCreadorIdSeleccionado] = useState(0)
    const [facturaIdSeleccionada, setFacturaIdSeleccionada] = useState(0)


    // const history = useHistory()

    // const [selectionModel, setSelectionModel] = React.useState([]);
    // const [comercialSeleccionado, setComercialSeleccionado] = React.useState([]);

    // const [comerciales, setComerciales] = useState(undefined)

    /* -------------------------------------------------------------------------- */
    /*                                  UseEffect                                 */
    /* -------------------------------------------------------------------------- */
    useEffect(() => {
        setLoading(true)
        setLoadingMensaje("Cargando creadores")

        const usuario_id = usuarioSeleccionado.id

        getUsuariosFacturas({ usuario_id }).then((response) => {

            response && setFacturas(response.facturas)
            setLoading(false)

        });

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const actualizarListadoUsuariosFacturas = () => {
      getUsuariosFacturas({ usuario_id: usuarioSeleccionado.id }).then((response) => {

        response && setFacturas(response.facturas)

    });
    }
    const handleOpenModalCreadores = (valor) => {
      if(valor === false) {
        actualizarListadoUsuariosFacturas()
      }

      setOpenModalCreadores(valor)
    }

    const handleOpenModalFactura = (valor) => {
      if(valor === false) {
        actualizarListadoUsuariosFacturas()
      }

      setOpenModalFactura(valor)
    }
    const handleOpenModalGenerarExcelLiquidaciones = (valor) => {
      setOpenModalGenerarExcelLiquidaciones(valor)
    }
    /* -------------------------------------------------------------------------- */
    /*                           Definicion de columnas                           */
    /* -------------------------------------------------------------------------- */

    
    const columnas = [
      { field: "id", hide: false, headerName: "ID" },
      {
        field: "usuario_nombre",
        headerName: "Nombre",
        minWidth: 350,
        renderCell: (cellValues) => {
          return <>{cellValues.row.usuario_nombre}</>;
        },
      },
        { field: 'concepto', headerName: 'Concepto',minWidth: 320, flex: 1 },
        { field: 'detalle', headerName: 'Detalle',minWidth: 320, flex: 1 },
        { field: 'es_publico', headerName: 'Publico',minWidth: 100, editable: (usuarioSeleccionado.perfil_id !== "12" && usuarioSeleccionado.perfil_id !== "13") ? true : false }, /* Jefatura no puede editar */
        { field: 'es_pagada', headerName: 'Pagada',minWidth: 100, editable: (usuarioSeleccionado.perfil_id !== "12" && usuarioSeleccionado.perfil_id !== "13") ? true : false }, /* Jefatura no puede editar */
        {
          'field': 'fecha',
          'width':120,
          'headerName': 'Fecha',
          type: 'date',
          renderCell: (cellValues) => {
            return (

                (cellValues.value !== null && cellValues.value !== "0000-00-00 00:00:00") ?

                <Moment format="DD/MM/YYYY">
                  {cellValues.value}
                </Moment>

                :

                <></>

            );
          }
        },
        { field: 'periodo', headerName: 'Fecha',minWidth: 200, renderCell: (cellValues) => `${cellValues.row.mes}-${cellValues.row.anyo}` },
        { field: 'precio', headerName: 'Importe',minWidth: 150, renderCell: (cellValues) => `${cellValues.row.precio}€` },
        {
          'field': 'imprimir_factura', 
          'width':50, 
          'headerName': '', 
          'description': '',
          renderCell: (cellValues) => {
            return (
              <>
              <a href={`https://gestion.innovatiio.com/api/v1/alumno/usuario_factura_pdf?id=${cellValues.row.id}`} target="_blank" rel="noreferrer">
                <PictureAsPdfIcon/>
              </a>
              </>
            )
          }
        },
      {
        field: "actions",
        type: "actions",
        width: 80,
        getActions: (params) => [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Modificar creador"
            onClick={ () => {
              setUsuarioCreadorIdSeleccionado(params.row.usuario_id)
              setOpenModalCreadores(true)
            }}
            // component={Link}
            // to={`alumnos/detalle/${params.id}`}
            showInMenu
          />,
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Modificar factura"
            onClick={ () => {
              if(params.row.es_publico === 's') {
                Swal.fire("No se puede modificar una factura publica")
                return;
              }
              setUsuarioCreadorIdSeleccionado(0)

              setFacturaIdSeleccionada(params.row.id)
              setOpenModalFactura(true)
            }}
            // component={Link}
            // to={`alumnos/detalle/${params.id}`}
            showInMenu
          />,
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Borrar factura"
            onClick={ () => {
              if(params.row.es_publico === 's') {
                Swal.fire("No se puede modificar una factura publica")
                return;
              }

              Swal.fire({
                title: '¿Realmente deseas borrar la factura',
                showDenyButton: true,
                confirmButtonText: 'Si',
               denyButtonText: `No`,
               }).then((result) => {
                if (result.isConfirmed) {
                  delete_usuario_factura({usuario_id: usuarioSeleccionado.id, factura_id: params.row.id}).then( response => {
                    actualizarListadoUsuariosFacturas()

                    Swal.fire("Factura eliminada");

                  })
                }
                })

            }}
            // component={Link}
            // to={`alumnos/detalle/${params.id}`}
            showInMenu
          />,
        ],
      },
    ];

    /* -------------------------------------------------------------------------- */
    /*                                  Contenido                                 */
    /* -------------------------------------------------------------------------- */
    function QuickSearchToolbar(props) {
        const classes = useStyles();
      
        return (
          <div className={classes.rootFilter}>
            <div>
              <GridToolbarFilterButton />
              <GridToolbarDensitySelector />
            </div>
            <TextField
              variant="standard"
              value={props.value}
              onChange={props.onChange}
              placeholder="Buscar…"
              className={classes.textFieldFilter}
              InputProps={{
                startAdornment: <SearchIcon fontSize="small" />,
                endAdornment: (
                  <IconButton
                    title="Clear"
                    aria-label="Clear"
                    size="small"
                    style={{ visibility: props.value ? 'visible' : 'hidden' }}
                    onClick={props.clearSearch}
                  >
                    <ClearIcon fontSize="small" />
                  </IconButton>
                ),
              }}
            />
          </div>
        );
      }
      
      QuickSearchToolbar.propTypes = {
        clearSearch: PropTypes.func.isRequired,
        onChange: PropTypes.func.isRequired,
        value: PropTypes.string.isRequired,
      };

    const Contenido = () => {
        const [rows, setRows] = React.useState(facturas);
        const [searchText, setSearchText] = React.useState('');
        const requestSearch = (searchValue) => {
        //   // console.log(searchValue)
          setSearchText(searchValue);
          const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
          // const searchRegex = new RegExp(`.*${searchValue}.*`, 'ig');
          const filteredRows = facturas.filter((row) => {
              return Object.keys(row).some((field) => {
                  // console.log(field)
                  // console.log(row)
                  return searchRegex.test(row[field]);
              });
          });
          setRows(filteredRows);
        };
        const [anchorElOpciones, setAnchorElOpciones] = React.useState(null);
        const open = Boolean(anchorElOpciones);
      
        const handleClickOpciones = (event) => {
          setAnchorElOpciones(event.currentTarget);
        };
      
        const handleCloseOpciones = () => {
          setAnchorElOpciones(null);
        };
        // const handleAlumnoDetalle = (id) => {
        //   history.push('alumnos/detalle/' + id )
        // }
        return rows ? (
          <>
            <Box mt={12} mb={3} mx={2}>
              <Stack direction="row" spacing={2}>
                <Typography
                  variant="h5"
                  component="h3"
                  gutterBottom
                  display="inline"
                  style={{ fontWeight: 500 }}
                >
                  {"Facturación creadores"}
                </Typography>

                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => handleOpenModalCreadores(true)}
                >
                  Nuevo creador
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => handleOpenModalFactura(true)}
                >
                  Nueva factura
                </Button>
                {
                  /* No lo puede ver jefatura */
                  usuarioSeleccionado.perfil_id !== "12" &&
                    usuarioSeleccionado.perfil_id !== "13" && (
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() =>
                          handleOpenModalGenerarExcelLiquidaciones(true)
                        }
                      >
                        Generar Excel Liquidaciones
                      </Button>
                    )
                }
              </Stack>

            </Box>

            <div style={{ height: 500, width: "100%", padding: "0 15px" }}>
              <DataGrid
                rows={rows}
                columns={columnas}
                rowHeight={30}
                components={{ Toolbar: QuickSearchToolbar }}
                onCellEditCommit={(item) => {
                  facturaUsuarioUpdateCampoValor({
                    usuario_id: usuarioSeleccionado.id,
                    factura_id: item.id,
                    campo: item.field,
                    valor: item.value,
                  }).then((response) => {
                    console.log(response);
                  });
                }}
                // onRowDoubleClick={ (evento ) => handleAlumnoDetalle(evento.id) }
                componentsProps={{
                  toolbar: {
                    value: searchText,
                    onChange: (event) => requestSearch(event.target.value),
                    clearSearch: () => requestSearch(""),
                  },
                }}
              />
            </div>

            <DialogModalCreador
              openModal={openModalCreadores}
              setOpenModal={handleOpenModalCreadores}
              creador_id={usuarioCreadorIdSeleccionado}
            />
            <DialogModalFactura
              openModal={openModalFactura}
              setOpenModal={handleOpenModalFactura}
              factura_id={facturaIdSeleccionada}
            />
            <DialogModalGenerarExcelLiquidaciones
              openModal={openModalGenerarExcelLiquidaciones}
              setOpenModal={handleOpenModalGenerarExcelLiquidaciones}
            />
          </>
        ) : (
          <EmptyComponent mensaje="No hay datos para mostrar" />
        );
    }
    
    /* -------------------------------------------------------------------------- */
    /*                                   Return                                   */
    /* -------------------------------------------------------------------------- */
    return (
        <div className={classes.container}>
            {
                (
                  
                  loading ?
                    
                  <LoadingComponent isFullScreen={true} mensaje={loadingMensaje} />
                    
                  :
                    
                  <Contenido />

                )
            }
        </div>
    )
}
