import React, { useEffect } from 'react'
import { useState } from "react";

import { makeStyles } from '@material-ui/core/styles';

import LoadingComponent from '../../../components/Loading';

import { useSelector } from "react-redux";

import {
    selectUsuarioSeleccionado,
} from "../../../redux/reducer/loginReducer";
import EmptyComponent from '../../../components/EmptyContent';
// import { deleteInquilino, getAlumnos, inquilinoDelete } from '../../../../services/Alumnos';
import { Box, Button, Chip, FormControl, Grid, IconButton, InputLabel, Menu, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@material-ui/core';
import { DataGrid, GridActionsCellItem, GridToolbarDensitySelector, GridToolbarFilterButton} from '@mui/x-data-grid';
import EditIcon from '@material-ui/icons/Edit';
import SyncIcon from '@material-ui/icons/Sync';
import Swal from 'sweetalert2';
import { Link, useHistory } from 'react-router-dom';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import PropTypes from 'prop-types';
import TituloPagina from '../../../components/Titulos/TituloPagina';
import AddIcon from '@material-ui/icons/Add';
import { getAlumnos, getAlumnosBaja, getAlumnosCoordinador, getAlumnosPosibleBaja, getAlumnosRegistrados, getMetricasTicket, getTutoresGestion, getTutoriasIndividualesGestion, update_comercial } from '../../../services/alumnos';
import { Stack } from '@mui/system';
import {sincronizar_formularios, sincronizar_notion, sincronizar_woo_order} from '../../../services/sincronizar'
import { getComerciales } from '../../../services/comerciales';
import Moment from 'react-moment';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { BASE_URL_SERVICE, BASE_URL_ZENDESK } from '../../../constants';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { number } from 'yup';
import DataGridInnovattio from '../../../components/DataGridInnovatiio';
import { grey } from '@material-ui/core/colors';
import StatusEntregasAlumno from '../../../components/StatusEntregasAlumno'
import StatusTicketsAlumno from '../../../components/StatusTicketsAlumno'
import LeyendaEstadosTutor from '../../../components/LeyendaEstadosTutor'

function escapeRegExp(value) {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
  }

// import Moment from 'react-moment';

/* -------------------------------------------------------------------------- */
/*                                   Estilos                                  */
/* -------------------------------------------------------------------------- */
const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: '80px'
    },
    loadingContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "90vh"
    },
    emptyContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "80vh",
        width: '100%',
        flexDirection: 'column',
        textAlign: 'center'
    },
    emptyImage: {
        width: '100%'
    },
    rootFilter: {
        padding: theme.spacing(0.5, 0.5, 0),
        justifyContent: 'space-between',
        display: 'flex',
        alignItems: 'flex-start',
        flexWrap: 'wrap',
      },
    textFieldFilter: {
        [theme.breakpoints.down('xs')]: {
          width: '100%',
        },
        margin: theme.spacing(1, 0.5, 1.5),
        '& .MuiSvgIcon-root': {
          marginRight: theme.spacing(0.5),
        },
        '& .MuiInput-underline:before': {
          borderBottom: `1px solid ${theme.palette.divider}`,
        },
      },
    enlace: {
      color: theme.palette.link.main,
      textDecoration: 'none'
    }
}));

export default function AlumnosGestionAcademicaContent() {
  const classes = useStyles();

  const [alumnos, setAlumnos] = useState([]);
  const [loading, setLoading] = useState(false)
  const [loadingMensaje, setLoadingMensaje] = useState("")

  const usuarioSeleccionado = useSelector(state => {
      return selectUsuarioSeleccionado(state);
  });
  const history = useHistory()

  // const [selectionModel, setSelectionModel] = React.useState([]);
  // const [comercialSeleccionado, setComercialSeleccionado] = React.useState([]);

  // const [comerciales, setComerciales] = useState(undefined)

  /* -------------------------------------------------------------------------- */
  /*                                  UseEffect                                 */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
      setLoading(true)
      setLoadingMensaje("Cargando alumnos")

      const usuario_id = usuarioSeleccionado.id

      console.log(usuarioSeleccionado)

      
      getAlumnosCoordinador({ usuario_id: usuario_id, coordinador_usuario_id:-1 }).then((response) => {
        setLoading(false)

        response && setAlumnos(response.alumnos)
      });

  }, [])

  /* -------------------------------------------------------------------------- */
  /*                           Definicion de columnas                           */
  /* -------------------------------------------------------------------------- */

  const columnas = 
  [
    { field: 'id',hide: true, headerName: 'ID', minWidth: 10 },
    { 
      field: 'nombre', 
      headerName: 'Nombre', 
      minWidth: 150, 
      flex: 1, 
      renderCell: (celda) => {
          return celda.row.nombre
      }
    },
    { field: 'tutor_nombre', headerName: 'Tutor',minWidth: 10, flex: 1 },
    { field: 'entregas_plantilla_descripcion', headerName: 'Grupo',minWidth: 10, flex: 1 },
    { field: 'comunidad_autonoma_nombre', headerName: 'Comunidad',minWidth: 100, flex: 1 },
    { field: 'oposicion_nombre', headerName: 'Oposición',minWidth: 90 },
    { field: 'especialidad_nombre', headerName: 'Especialidad',minWidth: 220 },
    // {
    //   'field': 'estado_tutor', 
    //   'width':160, 
    //   'headerName': 'Estado tutor', 
    //   'description': 'Estado tutor',
    //   // type: 'date',
    //   // valueFormatter: params => moment(params?.value).format("DD/MM/YYYY hh:mm A"),
    //   renderCell: (cellValues) => {
    //     return (
            
    //       <StatusTutor num_tickets_entregas_abiertos={cellValues.row.num_tickets_entregas_abiertos} />
          
    //     );
    //   } 
    // },
    {
      'field': 'estado_alumno', 
      'width':230, 
      'headerName': 'Entregas', 
      'description': 'Estado entregas alumno',
      // type: 'date',
      // valueFormatter: params => moment(params?.value).format("DD/MM/YYYY hh:mm A"),
      renderCell: (cellValues) => {
        return (
          
          <>
          <StatusEntregasAlumno 
            num_tickets_resueltos={cellValues.row.num_tickets_entregas_resueltos} 
            num_tickets_abiertos={cellValues.row.num_tickets_entregas_abiertos} 
            num_tickets_pendientes={cellValues.row.num_tickets_entregas_pendientes} 
            num_tickets_retrasados={cellValues.row.num_tickets_entregas_retrasados} />
          </>
          
        );
      } 
    },
    {
      'field': 'estado_alumno_simulacros', 
      'width':230, 
      'headerName': 'Simulacros', 
      'description': 'Simulacros',
      // type: 'date',
      // valueFormatter: params => moment(params?.value).format("DD/MM/YYYY hh:mm A"),
      renderCell: (cellValues) => {
        return (
          
          <>
          <StatusEntregasAlumno 
            num_tickets_resueltos={cellValues.row.num_tickets_simulacros_resueltos} 
            num_tickets_abiertos={cellValues.row.num_tickets_simulacros_abiertos} 
            num_tickets_pendientes={cellValues.row.num_tickets_simulacros_pendientes} 
            num_tickets_retrasados={cellValues.row.num_tickets_simulacros_retrasados} />
          </>
          
        );
      } 
    },
    
    {
      'field': 'dudas', 
      'width':200, 
      'headerName': 'Dudas', 
      'description': 'Dudas',
      // type: 'date',
      // valueFormatter: params => moment(params?.value).format("DD/MM/YYYY hh:mm A"),
      renderCell: (cellValues) => {
        return (
          
          <>
            <StatusTicketsAlumno 
              num_tickets_resueltos={cellValues.row.num_tickets_resueltos} 
              num_tickets_abiertos={cellValues.row.num_tickets_abiertos} 
              num_tickets_pendientes={cellValues.row.num_tickets_pendientes} 
              num_tickets_retrasados={cellValues.row.num_tickets_retrasados} 
            />
          </>
          
        );
      } 
    },
    { field: 'num_tutorias_bienvenida', headerName: 'T. Bienvenida',minWidth: 220, type:'number', valueGetter: ({ value }) => value && parseInt(value), renderCell: (cellValues) => parseInt(cellValues.row.num_tutorias_bienvenida) },
    { field: 'num_tutorias_realizadas', headerName: 'T. Realizadas',minWidth: 220, type:'number', valueGetter: ({ value }) => value && parseInt(value), renderCell: (cellValues) => parseInt(cellValues.row.num_tutorias_realizadas) },
    { field: 'num_tutorias_pendientes', headerName: 'T. pendientes',minWidth: 220, type:'number', valueGetter: ({ value }) => value && parseInt(value), renderCell: (cellValues) => parseInt(cellValues.row.num_tutorias_pendientes) },
  ]

  /* -------------------------------------------------------------------------- */
  /*                                  Contenido                                 */
  /* -------------------------------------------------------------------------- */
  function QuickSearchToolbar(props) {
      const classes = useStyles();
    
      return (
        <div className={classes.rootFilter}>
          <div>
            <GridToolbarFilterButton />
            <GridToolbarDensitySelector />
          </div>
          <TextField
            variant="standard"
            value={props.value}
            onChange={props.onChange}
            placeholder="Buscar…"
            className={classes.textFieldFilter}
            InputProps={{
              startAdornment: <SearchIcon fontSize="small" />,
              endAdornment: (
                <IconButton
                  title="Clear"
                  aria-label="Clear"
                  size="small"
                  style={{ visibility: props.value ? 'visible' : 'hidden' }}
                  onClick={props.clearSearch}
                >
                  <ClearIcon fontSize="small" />
                </IconButton>
              ),
            }}
          />
        </div>
      );
  }
    
  // QuickSearchToolbar.propTypes = {
  //   clearSearch: PropTypes.func.isRequired,
  //   onChange: PropTypes.func.isRequired,
  //   value: PropTypes.string.isRequired,
  // };

  const Contenido = () => {
      const [rows, setRows] = React.useState(alumnos);
      // const [searchText, setSearchText] = React.useState('');
      // const requestSearch = (searchValue) => {
      //   // console.log(searchValue)
      //   setSearchText(searchValue);
      //   const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
      //   // const searchRegex = new RegExp(`.*${searchValue}.*`, 'ig');
      //   const filteredRows = Alumnos.filter((row) => {
      //       return Object.keys(row).some((field) => {
      //           // console.log(field)
      //           // console.log(row)
      //           return searchRegex.test(row[field]);
      //       });
      //   });
      //   setRows(filteredRows);
      // };

      // const handleAlumnoDetalle = (id) => {
      //   history.push('/alumnos_tutor/detalle/' + id )
      // }

      return (
          rows ?
              <>
                   <TituloPagina titulo={"Alumnos de tus tutores"} />

                  <Box>
                    <div style={{ height: 700, width: '100%', padding:"0 15px" }}>
                    
                        <DataGridInnovattio
                            rows={rows} 
                            columns={columnas} 
                            rowHeight={30}
                            components={{ Toolbar: QuickSearchToolbar }}
                            // onDoubleClick={ (evento ) => handleAlumnoDetalle(evento.id) }
                            // componentsProps={{
                            //     toolbar: {
                            //       value: searchText,
                            //       onChange: (event) => requestSearch(event.target.value),
                            //       clearSearch: () => requestSearch(''),
                            //     },
                            // }}
                        />
                    </div>
                  </Box>
                  <LeyendaEstadosTutor />
              </>
              
              :
              
              <EmptyComponent mensaje="No hay datos para mostrar" />
      )
  }
  
  /* -------------------------------------------------------------------------- */
  /*                                   Return                                   */
  /* -------------------------------------------------------------------------- */
  return (
      <div className={classes.container}>
          {
              (loading ?
                  
                  <LoadingComponent isFullScreen={true} mensaje={loadingMensaje} />
                  
                  :
                  
                  <Contenido />
              )
          }
      </div>
  )
}
