import { AppBar, Box, Button, Chip, Divider, FormControl, FormHelperText, Grid, Hidden, Input, InputLabel, makeStyles, MenuItem, Paper, Select, Tab, Tabs, TextField, Tooltip, Typography } from '@material-ui/core';
import { useFormik, useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
// import "../../assets/css/master.css";

import { selectUsuarioSeleccionado } from '../../../redux/reducer/loginReducer';
import * as yup from 'yup';
// import { addFactura } from '../../../services/inquilino';
import { DataGrid } from '@mui/x-data-grid';
import TemplateForm from '../../../components/TemplateForm/TemplateForm';
import { insert_nota, insert_nota_seguimiento, update_nota_fecha_aviso, update_nota_seguimiento_fecha_aviso, update_nota_seguimiento_motivo_llamada } from '../../../services/alumnos_notas';
import { KeyboardDatePicker, KeyboardDateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import Moment from 'react-moment';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { getListaDetalle } from '../../../services/lista_detalle';
import esLocale from 'date-fns/locale/es';

/* -------------------------------------------------------------------------- */
/*                                   Estilos                                  */
/* -------------------------------------------------------------------------- */
const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: 90
        // display: 'flex',
        // flexWrap: 'wrap',
    },
    textField: {
        // marginLeft: theme.spacing(1),
        // marginRight: theme.spacing(1),
    },
    keyboardDatePicker: {
        // marginLeft: theme.spacing(1),
        // marginRight: theme.spacing(1),
    },
    formulario: {
        margin: 8
    },
    input: {
        display: 'none'
    },
}));

/* -------------------------------------------------------------------------- */
/*                            Esquema de valicación                           */
/* -------------------------------------------------------------------------- */
const validationSchema = yup.object({

    motivo_llamada_id: yup.number().required("Motivo requerido"),
    // apellidos: yup.string().required("Apellidos son requeridos"),
    // telefono: yup.string().required("Telefono es requerido"),
    // correo: yup.string().email().required("Correo es requerido"),
    
  });


const AlumnosNotasSeguimientoForm = ({alumno_id, notas_seguimiento}) => {
    // const classes = useStyles();

    let history = useHistory();
    const usuarioSeleccionado = useSelector(state => {
        return selectUsuarioSeleccionado(state);
    });
    const [loading, setLoading] = useState(false)
    const [alumno, setAlumno] = useState(undefined)
    const [notasSeguimientoAlumno, setNotasSeguimientoAlumno] = useState(notas_seguimiento)
    
    const [initialValues, setInitialValues] = useState({
        nota:  "",
        motivo_llamada_id: 155,
        fecha_aviso:  null,
    })
    const [notaSeleccionada, setNotaSeleccionada] = React.useState(undefined);
    const [motivosLlamada, setMotivosLlamada] = React.useState(undefined)
    /* -------------------------------------------------------------------------- */
    /*                                  UseEffect                                 */
    /* -------------------------------------------------------------------------- */
    useEffect(() => {
        const usuario_id = usuarioSeleccionado.id
        
    //     setLoading(true);
    
        getListaDetalle({usuario_id: usuario_id, lista_id: 16}).then( (response) => {
            response.lista_detalle && setMotivosLlamada(response.lista_detalle)
        })

    // // const [comerciales, setComerciales] = useState(undefined)
    }, [])

    /* -------------------------------------------------------------------------- */
    /*                                  UseFormik                                 */
    /* -------------------------------------------------------------------------- */
    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: (values) => {

            insert_nota_seguimiento({
                usuario_id: usuarioSeleccionado.id,
                alumno_id: alumno_id,
                nota: values.nota,
                motivo_llamada_id: values.motivo_llamada_id,
                fecha_aviso: values.fecha_aviso
            }).then( (response) => {
                response && setNotasSeguimientoAlumno(response.notas_seguimiento)
                formik.setFieldValue('nota', "");
                formik.values.nota = "";
                formik.motivo_llamada_id = 155
                formik.fecha_aviso = null

            })

        },
      });

      
    const columnas_notas_seguimiento = [
        {
            'field': 'nota', 
            flex: 1,
            'headerName': 'Nota', 
            'description': 'Nota',
            renderCell: (cellValues) => {
                return (
                    <>
                    <Tooltip title={cellValues.value} >
                        <span className="table-cell-trucate">{cellValues.value}</span>
                    </Tooltip>
                    </>
                );
            } 
        },
        
        {
            'field': 'fecha_aviso', 
            'width':160, 
            'headerName': 'Aviso', 
            'description': 'Fecha de aviso',
            type: 'datetime',
            valueFormatter: params => moment(params?.value).format("DD/MM/YYYY hh:mm A"),
            renderCell: (cellValues) => {
              return (
                
                  (cellValues.value !== null && cellValues.value !== undefined && cellValues.value !== "0000-00-00 00:00:00") ?
          
                  <Moment format="DD/MM/YYYY">
                    {cellValues.value}
                  </Moment>
          
                  :
          
                  <></>
          
                
              );
            },
            editable: true 
          },
        { 
            field: 'motivo_llamada_nombre', 
            headerName: 'Motivo llamada', 
            maxWidth: 250, 
            flex: 1, 
            editable: true,
            type: 'singleSelect',
            // valueFormatter: params => {
            //     console.log(params)
            //     return '2'
            // },
            valueOptions: motivosLlamada && motivosLlamada.map( (item) => {
                return {
                    value: item.id,
                    label: item.nombre,
                }
            }),
            
        },
        { field: 'created_usuario_nombre', headerName: 'Creado por', maxWidth: 250, flex: 1 },
        {
            'field': 'created', 
            'width':160, 
            'headerName': 'Creado', 
            'description': 'Fecha de creación',
            type: 'date',
            valueFormatter: params => moment(params?.value).format("DD/MM/YYYY hh:mm A"),
            renderCell: (cellValues) => {
              return (
                
                  (cellValues.value !== null && cellValues.value !== undefined && cellValues.value !== "0000-00-00 00:00:00") ?
          
                  <Moment format="DD/MM/YYYY hh:mm:ss">
                    {cellValues.value}
                  </Moment>
          
                  :
          
                  <></>
          
                
              );
            },
          },

    ];

    return (
        <>
                <form noValidate method="POST" enctype="multipart/form-data" autoComplete="off" onSubmit={ formik.handleSubmit }>

                    <Grid container  spacing={2}>
                        <Grid item xs={12} md={7}>

                            <TextField
                                id="nota"
                                name="nota"
                                label="Nota"
                                placeholder="Añade una nota"
                                fullWidth
                                multiline
                                rows={3}
                                margin="normal"
                                value={formik.values.nota}
                                onChange={formik.handleChange}
                                variant="outlined"
                                error={formik.touched.nota && Boolean(formik.errors.nota)}
                                helperText={formik.touched.nota && formik.errors.nota}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <FormControl
                                variant="outlined"
                                fullWidth
                                style={{ marginTop: 16 }}
                                error={formik.touched.motivo_llamada_id && Boolean(formik.errors.motivo_llamada_id)}
                            >
                                <InputLabel id="motivo_llamada_id-label">Motivo llamada</InputLabel>
                                <Select
                                    labelId="motivo_llamada_id-label"
                                    id="motivo_llamada_id"
                                    name="motivo_llamada_id"
                                    label="Motivo llamada"
                                    fullWidth
                                    value={formik.values.motivo_llamada_id}
                                    onChange={formik.handleChange}
                                    error={formik.touched.motivo_llamada_id && Boolean(formik.errors.motivo_llamada_id)}
                                >
                                    
                                    {        
                                        motivosLlamada && motivosLlamada.map( (item) => {
                                            return (<MenuItem key={item.id} value={item.id}>{item.nombre}</MenuItem>)
                                        })
                                    }

                                </Select>
                                <FormHelperText>{formik.touched.motivo_llamada_id && formik.errors.motivo_llamada_id}</FormHelperText>
                            </FormControl>
                        </Grid>

                        <Grid item xs={6} md={2} style={{marginTop: 16}}>

                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                                <KeyboardDateTimePicker
                                    fullWidth
                                    autoOk={true}
                                    id="fecha_aviso"
                                    label="Fecha Aviso"
                                    placeholder='Introduce la fecha de aviso'
                                    inputVariant="outlined"
                                    format="dd/MM/yyyy"
                                    value={formik.values.fecha_aviso}
                                    onChange={value => formik.setFieldValue("fecha_aviso", value)}
                                    KeyboardButtonProps={{
                                        "aria-label": "change date"
                                    }}
                                    error={formik.touched.fecha_aviso && Boolean(formik.errors.fecha_aviso)}
                                    helperText={formik.touched.fecha_aviso && formik.errors.fecha_aviso}
                                />
                            </MuiPickersUtilsProvider>

                        </Grid>
                        

                        <Grid item xs={12} md={1} >

                            <Button 
                                fullWidth
                                variant="contained" 
                                color="primary" 
                                type="submit"
                                size='large'
                                style={{marginTop: '16px'}}
                            >
                                Guardar
                            </Button>

                        </Grid>
                    </Grid>
                </form>
            { 
                notasSeguimientoAlumno && 
                    <div style={{ height: 500, width: '100%', padding:"" }}>
                        <DataGrid 
                            getRowHeight={() => 'auto'}
                            rows={notasSeguimientoAlumno} 
                            columns={columnas_notas_seguimiento} 
                            onCellEditCommit={(params) => {
                                if(params.field === "fecha_aviso") {
                                    update_nota_seguimiento_fecha_aviso({
                                        usuario_id: usuarioSeleccionado.id,
                                        nota_id: params.id,
                                        fecha_aviso: params.value
                                    }).then( (response) => {
                                        console.log("🚀 ~ file: AlumnosNotasForm.js:278 ~ AlumnosNotasForm ~ response:", response)
                                    })
                                }
                                if(params.field === "motivo_llamada_nombre") {
                                    update_nota_seguimiento_motivo_llamada({
                                        usuario_id: usuarioSeleccionado.id,
                                        nota_id: params.id,
                                        motivo_llamada_id: params.value
                                    }).then( (response) => {
                                        setNotasSeguimientoAlumno(response.notas_seguimiento)
                                    })
                                }
                            }}
                        />
                    </div>
                }


        </>
    )
};

export default AlumnosNotasSeguimientoForm;