import axios from 'axios';
import {
    BASE_URL_SERVICE,
} from '../constants'
import { dateToMysql } from '../utils/dateTime';

/* -------------------------------------------------------------------------- */
/*                            Insertar cuota                               */
/* -------------------------------------------------------------------------- */
export async function insertar_alumno_producto_cuota({ usuario_id, alumno_producto_id, fecha, importe, num_cuotas }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);
        formData.append("alumno_producto_id", alumno_producto_id);
        formData.append("fecha", dateToMysql (fecha));
        formData.append("importe_cuota", importe);
        formData.append("num_cuotas", num_cuotas);

        const response = await axios({
           method: 'post',
           url: BASE_URL_SERVICE + 'alumno/insertar_alumno_producto_cuota',
           data: formData,
           headers: {
            // 'Content-Type': 'multipart/form-data',
            // 'Authorization': "Bearer eyJ0eciOiJSUzI1NiJ9.eyJMiIsInNjb3BlcyI6W119.K3lW1STQhMdxfAxn00E4WWFA3uN3iIA", 
            // 'X-AUTH-TOKEN': "1231231231231231"
           },
    //   crossorigin: true,    
    //   mode: 'no-cors',      
        });

        return response.data;
    } catch (error) {
        return undefined;
        // throw error;
    }

}

/* -------------------------------------------------------------------------- */
/*                            Eliminar cuota                               */
/* -------------------------------------------------------------------------- */
export async function eliminar_alumno_producto_cuota({ usuario_id, alumno_producto_cuota_id }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);
        formData.append("alumno_producto_cuota_id", alumno_producto_cuota_id);

        const response = await axios({
           method: 'post',
           url: BASE_URL_SERVICE + 'alumno/eliminar_alumno_producto_cuota',
           data: formData,
           headers: {
            // 'Content-Type': 'multipart/form-data',
            // 'Authorization': "Bearer eyJ0eciOiJSUzI1NiJ9.eyJMiIsInNjb3BlcyI6W119.K3lW1STQhMdxfAxn00E4WWFA3uN3iIA", 
            // 'X-AUTH-TOKEN': "1231231231231231"
           },
    //   crossorigin: true,    
    //   mode: 'no-cors',      
        });

        return response.data;
    } catch (error) {
        return undefined;
        // throw error;
    }

}

/* -------------------------------------------------------------------------- */
/*                            Enviar enlace de pago                           */
/* -------------------------------------------------------------------------- */
export async function enviar_enlace_pago_alumno_producto_cuota({ usuario_id, alumno_producto_cuota_id }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);
        formData.append("alumno_producto_cuota_id", alumno_producto_cuota_id);

        const response = await axios({
           method: 'post',
           url: BASE_URL_SERVICE + 'alumno/enviar_enlace_pago_alumno_producto_cuota',
           data: formData,
           headers: {
            // 'Content-Type': 'multipart/form-data',
            // 'Authorization': "Bearer eyJ0eciOiJSUzI1NiJ9.eyJMiIsInNjb3BlcyI6W119.K3lW1STQhMdxfAxn00E4WWFA3uN3iIA", 
            // 'X-AUTH-TOKEN': "1231231231231231"
           },
    //   crossorigin: true,    
    //   mode: 'no-cors',      
        });

        return response.data;
    } catch (error) {
        return undefined;
        // throw error;
    }

}
export async function crear_enlace_pago({ usuario_id, alumno_producto_cuota_id }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);
        formData.append("alumno_producto_cuota_id", alumno_producto_cuota_id);

        const response = await axios({
           method: 'post',
           url: BASE_URL_SERVICE + 'alumno/crear_enlace_pago',
           data: formData,
           headers: {
            // 'Content-Type': 'multipart/form-data',
            // 'Authorization': "Bearer eyJ0eciOiJSUzI1NiJ9.eyJMiIsInNjb3BlcyI6W119.K3lW1STQhMdxfAxn00E4WWFA3uN3iIA", 
            // 'X-AUTH-TOKEN': "1231231231231231"
           },
    //   crossorigin: true,    
    //   mode: 'no-cors',      
        });

        return response.data;
    } catch (error) {
        return undefined;
        // throw error;
    }

}

/* -------------------------------------------------------------------------- */
/*                            Enviar enlace de pago                           */
/* -------------------------------------------------------------------------- */
export async function enviar_cobro_alumno_producto_cuota({ usuario_id, alumno_producto_cuota_id }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);
        formData.append("alumno_producto_cuota_id", alumno_producto_cuota_id);

        const response = await axios({
           method: 'post',
           url: BASE_URL_SERVICE + 'alumno/enviar_cobro_alumno_producto_cuota',
           data: formData,
           headers: {
            // 'Content-Type': 'multipart/form-data',
            // 'Authorization': "Bearer eyJ0eciOiJSUzI1NiJ9.eyJMiIsInNjb3BlcyI6W119.K3lW1STQhMdxfAxn00E4WWFA3uN3iIA", 
            // 'X-AUTH-TOKEN': "1231231231231231"
           },
    //   crossorigin: true,    
    //   mode: 'no-cors',      
        });

        return response.data;
    } catch (error) {
        return undefined;
        // throw error;
    }

}

/* -------------------------------------------------------------------------- */
/*                            Enviar enlace de pago                           */
/* -------------------------------------------------------------------------- */
export async function enviar_cobro_manual_alumno_producto_cuota({ usuario_id, alumno_producto_cuota_id }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);
        formData.append("alumno_producto_cuota_id", alumno_producto_cuota_id);

        const response = await axios({
           method: 'post',
           url: BASE_URL_SERVICE + 'alumno/enviar_cobro_manual_alumno_producto_cuota',
           data: formData,
           headers: {
            // 'Content-Type': 'multipart/form-data',
            // 'Authorization': "Bearer eyJ0eciOiJSUzI1NiJ9.eyJMiIsInNjb3BlcyI6W119.K3lW1STQhMdxfAxn00E4WWFA3uN3iIA", 
            // 'X-AUTH-TOKEN': "1231231231231231"
           },
    //   crossorigin: true,    
    //   mode: 'no-cors',      
        });

        return response.data;
    } catch (error) {
        return undefined;
        // throw error;
    }

}

/* -------------------------------------------------------------------------- */
/*                            Enviar enlace de pago                           */
/* -------------------------------------------------------------------------- */
export async function crear_factura_alumno_producto_cuota({ usuario_id, alumno_producto_cuota_id }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);
        formData.append("alumno_producto_cuota_id", alumno_producto_cuota_id);

        const response = await axios({
           method: 'post',
           url: BASE_URL_SERVICE + 'alumno/crear_factura_alumno_producto_cuota',
           data: formData,
           headers: {
            // 'Content-Type': 'multipart/form-data',
            // 'Authorization': "Bearer eyJ0eciOiJSUzI1NiJ9.eyJMiIsInNjb3BlcyI6W119.K3lW1STQhMdxfAxn00E4WWFA3uN3iIA", 
            // 'X-AUTH-TOKEN': "1231231231231231"
           },
    //   crossorigin: true,    
    //   mode: 'no-cors',      
        });

        return response.data;
    } catch (error) {
        return undefined;
        // throw error;
    }

}
export async function generar_excel_remesas({ usuario_id }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);

        const response = await axios({
           method: 'get',
           url: BASE_URL_SERVICE + 'alumno/generar_excel_remesas',
           data: formData,
           responseType: 'blob', // Important
           headers: {
            'Content-Type': 'application/octet-stream'
            // 'Content-Type': 'multipart/form-data',
            // 'Authorization': "Bearer eyJ0eciOiJSUzI1NiJ9.eyJMiIsInNjb3BlcyI6W119.K3lW1STQhMdxfAxn00E4WWFA3uN3iIA", 
            // 'X-AUTH-TOKEN': "1231231231231231"
           },
    //   crossorigin: true,    
    //   mode: 'no-cors',      
        });

        return response.data;
    } catch (error) {
        return undefined;
        // throw error;
    }

}
export async function crear_abono_alumno_producto_cuota({ usuario_id, alumno_producto_cuota_id }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);
        formData.append("alumno_producto_cuota_id", alumno_producto_cuota_id);

        const response = await axios({
           method: 'post',
           url: BASE_URL_SERVICE + 'alumno/crear_abono_alumno_producto_cuota',
           data: formData,
           headers: {
            // 'Content-Type': 'multipart/form-data',
            // 'Authorization': "Bearer eyJ0eciOiJSUzI1NiJ9.eyJMiIsInNjb3BlcyI6W119.K3lW1STQhMdxfAxn00E4WWFA3uN3iIA", 
            // 'X-AUTH-TOKEN': "1231231231231231"
           },
    //   crossorigin: true,    
    //   mode: 'no-cors',      
        });

        return response.data;
    } catch (error) {
        return undefined;
        // throw error;
    }

}

/* -------------------------------------------------------------------------- */
/*                            Enviar enlace de pago                           */
/* -------------------------------------------------------------------------- */
export async function actualizar_factura_alumno_producto_cuota({ usuario_id, factura_id }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);
        formData.append("factura_id", factura_id);

        const response = await axios({
           method: 'post',
           url: BASE_URL_SERVICE + 'alumno/actualizar_factura_alumno_producto_cuota',
           data: formData,
           headers: {
            // 'Content-Type': 'multipart/form-data',
            // 'Authorization': "Bearer eyJ0eciOiJSUzI1NiJ9.eyJMiIsInNjb3BlcyI6W119.K3lW1STQhMdxfAxn00E4WWFA3uN3iIA", 
            // 'X-AUTH-TOKEN': "1231231231231231"
           },
    //   crossorigin: true,    
    //   mode: 'no-cors',      
        });

        return response.data;
    } catch (error) {
        return undefined;
        // throw error;
    }

}

