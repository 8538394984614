import React from 'react';
// import "../../assets/css/master.css";

import TemplateDashboard from "../../../components/TemplateDashboard";
import AlumnosExposicionesOralesContent from './components/AlumnosExposicionesOralesContent';

const AlumnosExposicionesOralesListado = () => {

    return (
        <>

            <TemplateDashboard>
                <AlumnosExposicionesOralesContent />
            </TemplateDashboard>

        </>
    )
};

export default AlumnosExposicionesOralesListado;