import React from 'react';
// import "../../assets/css/master.css";

import TemplateDashboard from "../../components/TemplateDashboard";
import PlanificacionLlamadasContent from './components/PlanificacionLlamadasContent';

const PlanificacionLlamadasListado = () => {

    return (
        <>

            <TemplateDashboard>
                <PlanificacionLlamadasContent />
            </TemplateDashboard>

        </>
    )
};

export default PlanificacionLlamadasListado;