import React from 'react';

import TemplateDashboard from "../../components/TemplateDashboard";
import HomeContent from "./components/HomeContent"

const Home = () => {

    return (
        <>

            <TemplateDashboard>
                <HomeContent />
            </TemplateDashboard>

        </>
    )
};

export default Home;