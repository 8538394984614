import React, { useEffect } from "react";
import { useState } from "react";

import { makeStyles, withStyles } from "@material-ui/core/styles";

import LoadingComponent from "../../../components/Loading";

import { useSelector } from "react-redux";

import { selectUsuarioSeleccionado } from "../../../redux/reducer/loginReducer";
import EmptyComponent from "../../../components/EmptyContent";
// import { deleteInquilino, getAlumnos, inquilinoDelete } from '../../../../services/Alumnos';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import {
  DataGrid,
  GridActionsCellItem,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import EditIcon from "@material-ui/icons/Edit";
import SyncIcon from "@material-ui/icons/Sync";
import Swal from "sweetalert2";
import { Link, useHistory } from "react-router-dom";
import ClearIcon from "@material-ui/icons/Clear";
import SearchIcon from "@material-ui/icons/Search";
import PropTypes from "prop-types";
import TituloPagina from "../../../components/Titulos/TituloPagina";
import AddIcon from "@material-ui/icons/Add";
import {
  getAlumnos,
  getAvisosUsuario,
  update_comercial,
} from "../../../services/alumnos";
import { Stack } from "@mui/system";
import {
  sincronizar_formularios,
  sincronizar_notion,
  sincronizar_woo_order,
} from "../../../services/sincronizar";
import { getComerciales } from "../../../services/comerciales";
import {
  getCuotasMes,
  getFacturas,
  getProximasCuotas,
} from "../../../services/cuotas";
import Moment from "react-moment";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import EmailIcon from "@mui/icons-material/Email";

import {
  actualizar_factura_alumno_producto_cuota,
  generar_excel_remesas,
} from "../../../services/alumnos_productos_cuotas";
import {
  facturaUsuarioUpdateCampoValor,
  getLiquidacionTutores,
  getTutores,
  getTutoresPeriodosLiquidacion,
  liquidacionTutoresUpdateCampoValor,
  tutorRealizarLiquidaciones,
} from "../../../services/tutores";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  DataGridPro,
  useGridApiContext,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import { BASE_URL_SERVICE } from "../../../constants";
import axios from "axios";
import { green, grey } from "@material-ui/core/colors";
import { ToastContainer, toast } from "react-toastify";

function escapeRegExp(value) {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
}

// import Moment from 'react-moment';

/* -------------------------------------------------------------------------- */
/*                                   Estilos                                  */
/* -------------------------------------------------------------------------- */
const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: "80px",
  },
  loadingContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "90vh",
  },
}));

export default function LiquidacionTutoresContent() {
  const classes = useStyles();

  const [tutoresLiquidacion, setTutoresLiquidacion] = useState([]);
  const [tutores, setTutores] = useState([]);
  const [periodos, setPeriodos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingMensaje, setLoadingMensaje] = useState("");
  const [fieldModificado, setFieldModificado] = useState({});

  const usuarioSeleccionado = useSelector((state) => {
    return selectUsuarioSeleccionado(state);
  });
  // const history = useHistory()

  /* -------------------------------------------------------------------------- */
  /*                                  UseEffect                                 */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    setLoading(true);
    setLoadingMensaje("Cargando facturas");

    const usuario_id = usuarioSeleccionado.id;

    getTutoresPeriodosLiquidacion({ usuario_id }).then((response) => {
      response && setPeriodos(response.periodos);
    });

    getTutores({ usuario_id }).then((response) => {
      response && setTutores(response.tutores);
    });

    getLiquidacionTutores({ usuario_id }).then((response) => {
      response && setTutoresLiquidacion(response.tutores);
      setLoading(false);
    });
  }, [usuarioSeleccionado]);

  /* -------------------------------------------------------------------------- */
  /*                    Tratamiento de las columnas editables                   */
  /* -------------------------------------------------------------------------- */
  function TextFieldEditInputCell(props) {
    const { id, value, field } = props;
    const apiRef = useGridApiContext();

    const handleBlur = async (event) => {
      await apiRef.current.setEditCellValue({
        id,
        field,
        value: event.target.value,
      });
      // apiRef.current.stopCellEditMode({ id, field });

      console.log("Blur: ", field);
      //   if(field === 'invitado') {

      //     actualizarTutoriaInvitado({usuario_id: usuarioSeleccionado.id, tutoria_id: id, invitado: event.target.value}).then( response => {
      //       response && setTutorias(response.tutorias)

      //     })
      //   }
    };
    const handleChange = async (event) => {
      await apiRef.current.setEditCellValue({
        id,
        field,
        value: event.target.value,
      });
      // apiRef.current.stopCellEditMode({ id, field });
      console.log("Change: ", field);
    };

    // const handleBlur = async (event) => {
    //   console.log(event)
    // }

    return (
      <TextField
        fullWidth
        margin="normal"
        // value={value}
        onBlur={handleBlur}
        // onChange={handleChange}
        variant="outlined"
      />
      // <Select
      //   value={value}
      //   onChange={handleChange}
      //   size="small"
      //   sx={{ height: 1 }}
      //   native
      //   autoFocus
      // >
      //   <option>Back-end Developer</option>
      //   <option>Front-end Developer</option>
      //   <option>UX Designer</option>
      // </Select>
    );
  }
  const renderTextFieldEditInputCell = (params) => {
    return <TextFieldEditInputCell {...params} />;
  };
  /* -------------------------------------------------------------------------- */
  /*                           Definicion de columnas                           */
  /* -------------------------------------------------------------------------- */
  const columnasTutorFacturas = [
    { field: "id", hide: true, headerName: "ID" },
    { field: "cantidad", headerName: "Cantidad" },
    { field: "concepto", flex: 1, headerName: "Concepto" },
    {
      field: "precio",
      headerName: "Precio",
      renderCell: (celda) => {
        return <>{celda.value} €</>;
      },
    },
    { field: "es_publico", headerName: "Publico", width: 150, editable: true },
    {
      field: "imprimir_factura",
      width: 50,
      headerName: "",
      description: "",
      renderCell: (cellValues) => {
        return (
          <>
            <a
              href={`https://gestion.innovatiio.com/api/v1/alumno/usuario_factura_pdf?id=${cellValues.row.id}`}
              target="_blank"
              rel="noreferrer"
            >
              <PictureAsPdfIcon />
            </a>
          </>
        );
      },
    },
  ];

  const columnasTutorLiquidacion = [
    { field: "id", hide: true, headerName: "ID" },
    {
      field: "periodo",
      headerName: "Periodo",
      width: "120",
      renderCell: (cellValues) => {
        return (
          <>
            {cellValues.row.mes}/{cellValues.row.anyo}
          </>
        );
      },
    },
    {
      field: "num_alumnos_liquidacion",
      headerName: "Alumnos liquidacion",
      width: 150,
      renderCell: (cellValues) => {
        // if(cellValues.row.num_alumnos_liquidacion_es_prevision === '1'){
        return (
          <>
            {cellValues.value}
            <Chip
              size="small"
              style={{
                marginLeft: 10,
                backgroundColor: green[500],
                color: "white",
              }}
              label={cellValues.row.num_alumnos_periodo}
            />
          </>
        );
        // } else {
        //  return <>{cellValues.value}</>
        // }
      },
    },
    {
      field: "num_tutorias_individuales",
      headerName: "Tutorias individuales",
      width: 150,
      renderCell: (cellValues) => {
        return (
          <>
            {cellValues.value}
            <Chip
              size="small"
              style={{
                marginLeft: 10,
                backgroundColor: green[500],
                color: "white",
              }}
              label={cellValues.row.num_tutorias_individuales_periodo}
            />
          </>
        );
      },
    },
    {
      field: "num_sesiones_grupales",
      headerName: "Sesiones grupales",
      width: 150,
      renderCell: (cellValues) => {
        return (
          <>
            {cellValues.value}
            <Chip
              size="small"
              style={{
                marginLeft: 10,
                backgroundColor: green[500],
                color: "white",
              }}
              label={cellValues.row.num_tutorias_grupales_periodo}
            />
          </>
        );
      },
    },
    {
      field: "num_simulacros",
      headerName: "Simulacros",
      width: 150,
      renderCell: (cellValues) => {
        return (
          <>
            {cellValues.value}
            <Chip
              size="small"
              style={{
                marginLeft: 10,
                backgroundColor: green[500],
                color: "white",
              }}
              label={cellValues.row.num_simulacros_periodo}
            />
          </>
        );
      },
    },
    {
      field: "num_entregas",
      headerName: "Entregas",
      width: 150,
      renderCell: (cellValues) => {
        return (
          <>
            {cellValues.value}
            <Chip
              size="small"
              style={{
                marginLeft: 10,
                backgroundColor: green[500],
                color: "white",
              }}
              label={cellValues.row.num_entregas_periodo}
            />
          </>
        );
      },
    },
    {
      field: "num_exposiciones_orales_online",
      headerName: "EO online",
      width: 150,
      renderCell: (cellValues) => {
        return (
          <>
            {cellValues.value}
            <Chip
              size="small"
              style={{
                marginLeft: 10,
                backgroundColor: green[500],
                color: "white",
              }}
              label={cellValues.row.num_exposiciones_orales_online_periodo}
            />
          </>
        );
      },
    },
    {
      field: "num_exposiciones_orales_presencial",
      headerName: "EO presenciales",
      width: 150,
      renderCell: (cellValues) => {
        return (
          <>
            {cellValues.value}
            <Chip
              size="small"
              style={{
                marginLeft: 10,
                backgroundColor: green[500],
                color: "white",
              }}
              label={cellValues.row.num_exposiciones_orales_presencial_periodo}
            />
          </>
        );
      },
    },
    {
      field: "diferencia_alumnos",
      headerName: "Dif. Alumnos",
      width: 150,
      // valueSetter: (params)=>{
      //   // console.log(params)
      //   let value = parseFloat(params.value);
      //   if(isNaN(value) || value < 0){
      //     return {...params.row}
      //   }else{
      //     return {...params.row, diferencia_alumnos: value || ''}
      //   }

      // },
      editable: true,
    },
    {
      field: "diferencia_tutorias_individuales",
      headerName: "Dif. T. Ind",
      width: 150,
      editable: true,
    },
    {
      field: "diferencia_tutorias_grupales",
      headerName: "Dif. T. Grup",
      width: 150,
      editable: true,
    },
    {
      field: "diferencia_simulacros",
      headerName: "Dif. Simulacros",
      width: 150,
      editable: true,
    },
    {
      field: "diferencia_entregas",
      headerName: "Dif. Entregas",
      width: 150,
      editable: true,
    },
    {
      field: "diferencia_exposiciones_orales_online",
      headerName: "Dif. EO online",
      width: 150,
      editable: true,
    },
    {
      field: "diferencia_exposiciones_orales_presencial",
      headerName: "Dif. EO presenciales",
      width: 150,
      editable: true,
    },
    { field: "es_publico", headerName: "Publico", width: 150, editable: true },
    {
      field: "fecha_liquidacion",
      width: 190,
      headerName: "Fecha liquidación",
      description: "Fecha liquidación",
      renderCell: (cellValues) => {
        return cellValues.value !== null &&
          cellValues.value !== "0000-00-00 00:00:00" ? (
          <Moment format="DD/MM/YYYY HH:mm:ss">{cellValues.value}</Moment>
        ) : (
          <></>
        );
      },
    },
    { field: "uuid", headerName: "UUID", width: 150 },
    {
      field: "imprimir_liquidacion",
      width: 50,
      headerName: "",
      description: "",
      renderCell: (cellValues) => {
        return (
          <>
            <a
              href={`https://gestion.innovatiio.com/api/v1/alumno/tutor_liquidacion_pdf?id=${cellValues.row.id}`}
              target="_blank"
              rel="noreferrer"
            >
              <PictureAsPdfIcon />
            </a>
          </>
        );
      },
    },
    // {
    //   'field': 'enviar_liquidacion',
    //   'width':90,
    //   'headerName': '',
    //   'description': '',
    //   renderCell: (cellValues) => {
    //     return (
    //       <>
    //       <Button onClick={ () => handleTutorLiquidacionEnviarMail(cellValues.row.id) }>
    //         <EmailIcon style={{color: '#0B314E'}} />
    //       </Button>

    //       </>
    //     )
    //   }
    // },
  ];

  const columnasTutor = [
    // { field: 'id', hide: true, headerName: 'ID' },
    {
      field: "nombre",
      headerName: "Tutor",
      renderCell: (cellValues) => {
        return `${cellValues.row.nombre} ${cellValues.row.apellidos}`;
      },
      flex: true,
    },
    {
      field: "num_alumnos_asignados",
      headerName: "Alumnos Actuales",
      width: 150,
    },
  ];

  const columnasPeriodos = [
    // { field: 'id', hide: true, headerName: 'ID' },
    {
      field: "periodo",
      headerName: "Periodo",
      renderCell: (cellValues) => {
        return `${cellValues.row.mes}/${cellValues.row.anyo}`;
      },
      flex: true,
    },
  ];

  const handleTutorLiquidacionEnviarMail = (liquidacion_id) => {
    console.log(liquidacion_id);
  };

  /* -------------------------------------------------------------------------- */
  /*                                  Contenido                                 */
  /* -------------------------------------------------------------------------- */
  const TablaLiquidacion = ({ liquidaciones }) => {
    // const [seleccionModel, setSeleccionModel] = useState([])

    return (
      <>
        <Stack direction="column" spacing={2} style={{ width: "100vw" }}>
          {/* {seleccionModel.length > 0 && <Button 
              variant="contained" 
              color="primary" 
              type="submit"
              style={{height: 55}}
            >
              Liquidar {tutor_nombre}
            </Button>} */}

          <Typography variant="h6">Liquidaciones</Typography>

          <div style={{ height: 500, width: "100%", padding: "0 0px" }}>
            <DataGrid
              rows={liquidaciones}
              columns={columnasTutorLiquidacion}
              onCellEditCommit={(item) => {
                liquidacionTutoresUpdateCampoValor({
                  usuario_id: usuarioSeleccionado.id,
                  liquidacion_id: item.id,
                  campo: item.field,
                  valor: item.value,
                }).then((response) => {
                  console.log(response);
                });
              }}
            />
          </div>
        </Stack>
      </>
    );
  };
  const TablaFacturas = ({ facturas }) => {
    return (
      <>
        <Stack direction="column" spacing={2} style={{ width: "100vw" }}>
          <Typography variant="h6">Facturas</Typography>

          <div style={{ height: 500, width: "100%", padding: "0 0" }}>
            <DataGrid
              rows={facturas}
              columns={columnasTutorFacturas}
              onCellEditCommit={(item) => {
                facturaUsuarioUpdateCampoValor({
                  usuario_id: usuarioSeleccionado.id,
                  factura_id: item.id,
                  campo: item.field,
                  valor: item.value,
                }).then((response) => {
                  console.log(response);
                });
              }}
            />
          </div>
        </Stack>
      </>
    );
  };

  const Contenido = () => {
    const [tutoresSeleccionados, setTutoresSeleccionados] = useState([]);
    const [periodosSeleccionados, setPeriodosSeleccionados] = useState([]);
    const [numSesionesGrupales, setNumSesionesGrupales] = useState(1);

    const handleGenerarExcelRemesas = () => {
      axios({
        method: "get",
        url: BASE_URL_SERVICE + "alumno/generar_excel_remesas",
        params: { periodos: periodosSeleccionados },
        responseType: "blob", // Important
        headers: {
          "Content-Type": "application/octet-stream",
        },
      }).then((response) => {
        const path = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = path;
        link.setAttribute("download", "remesa.xlsx");
        document.body.appendChild(link);
        link.click();
      });
    };

    const solicitarExcelReservas = (periodosSeleccionadosIn) => {
      console.log(periodosSeleccionadosIn);
      axios({
        method: "get",
        url: BASE_URL_SERVICE + "alumno/generar_excel_remesas",
        params: { periodos: periodosSeleccionadosIn },
        responseType: "blob", // Important
        headers: {
          "Content-Type": "application/octet-stream",
        },
      }).then((response) => {
        const path = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = path;
        link.setAttribute("download", "remesa.xlsx");
        document.body.appendChild(link);
        link.click();
      });
    };
    return (
      <>
        <Box mx={2}>
          <Stack direction="row" spacing={2}>
            <Typography
              variant="h5"
              component="h3"
              gutterBottom
              style={{ fontWeight: 500 }}
            >
              Liquidación de tutores
            </Typography>

            <Button
              variant="outlined"
              color="primary"
              onClick={() => handleGenerarExcelRemesas()}
            >
              Generar Excel remesas (Selecciona al menos un periodo)
            </Button>

            <a
              href="https://gestion.innovatiio.com/api/procesos/actualizar_liquidacion_tutores"
              target="_blank"
              rel="noreferrer"
              style={{ paddingTop: 8 }}
            >
              Actualizar datos
            </a>
          </Stack>
        </Box>

        <Grid
          container
          mt={2}
          mb={3}
          mx={2}
          spacing={3}
          style={{ padding: 16 }}
        >
          {/* Titulo */}

          <Grid item xs={4}>
            <Paper style={{ padding: 10 }}>
              <div style={{ height: 500, width: "100%", padding: "0 15px" }}>
                <DataGridPro
                  rows={tutores}
                  columns={columnasTutor}
                  checkboxSelection
                  onRowSelectionModelChange={(newSelectionModel) => {
                    setTutoresSeleccionados(newSelectionModel);
                  }}
                />
              </div>
            </Paper>
          </Grid>
          <Grid item xs={4}>
            <Paper style={{ padding: 10 }}>
              <div style={{ height: 500, width: "100%", padding: "0 15px" }}>
                <DataGridPro
                  rows={periodos}
                  columns={columnasPeriodos}
                  checkboxSelection
                  onRowSelectionModelChange={(newSelectionModel) => {
                    setPeriodosSeleccionados(newSelectionModel);
                  }}
                />
              </div>
            </Paper>
          </Grid>
          <Grid item xs={4}>
            <Paper style={{ padding: 10 }}>
              <TextField
                id="sesiones_grupales"
                name="sesiones_grupales"
                label="Sesiones grupales"
                placeholder=""
                fullWidth
                readonly
                margin="normal"
                value={numSesionesGrupales}
                variant="outlined"
                onChange={(event) => {
                  let num = event.target.value;
                  if (num < 0) num = 0;
                  setNumSesionesGrupales(num);
                }}
                // error={formik.touched.formulario_nombre && Boolean(formik.errors.formulario_nombre)}
                // helperText={formik.touched.formulario_nombre && formik.errors.formulario_nombre}
                InputLabelProps={{
                  shrink: true,
                }}
              />

              <Button
                variant="contained"
                color="primary"
                // type="submit"
                style={{ height: 55 }}
                onClick={() => {
                  tutorRealizarLiquidaciones({
                    usuario_id: usuarioSeleccionado.id,
                    tutoresSeleccionados: tutoresSeleccionados,
                    periodosSeleccionados: periodosSeleccionados,
                    numSesionesGrupales: numSesionesGrupales,
                  }).then((response) => {

                    const toastInicio = toast(
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <CircularProgress style={{ marginRight: 10 }} />{" "}
                        <>Generando liquidaciones. Espera termine el proceso...</>
                      </div>,
                      { autoClose: false }
                    );

                    getLiquidacionTutores({
                      usuario_id: usuarioSeleccionado.id,
                    }).then((response) => {
                      // Swal.fire('Liquidación aplicada', '', 'info')
                      toast.dismiss(toastInicio);
                      toast.success("Liquidaciones generadas", {
                        autoClose: true,
                        closeButton: false
                      });
                      response && setTutoresLiquidacion(response.tutores);

                    solicitarExcelReservas(periodosSeleccionados);

                    });
                  });
                }}
              >
                Liquidar
              </Button>
            </Paper>
          </Grid>
        </Grid>

        <Box mt={2} mb={3} mx={2}>
          <Typography
            variant="h5"
            component="h3"
            gutterBottom
            style={{ fontWeight: 500 }}
          >
            Resumen de liquidaciones
          </Typography>

          {tutoresLiquidacion.map((tutor) => {
            return (
              <>
                {tutor.liquidaciones.length > 0 || tutor.facturas.length > 0 ? (
                  <Accordion key={tutor.id}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography style={{ width: 250 }}>
                        {tutor.nombre} {tutor.apellidos}
                      </Typography>

                      {tutor.es_tutor === "1" ? (
                        <Chip
                          size="small"
                          variant="outlined"
                          style={{
                            borderColor: grey[500],
                            color: grey[500],
                            marginLeft: 5,
                          }}
                          label={"Tutor"}
                        />
                      ) : (
                        <></>
                      )}
                      {tutor.es_coordinador === "1" ? (
                        <Chip
                          size="small"
                          variant="outlined"
                          style={{
                            borderColor: grey[500],
                            color: grey[500],
                            marginLeft: 5,
                          }}
                          label={"Coordinador"}
                        />
                      ) : (
                        <></>
                      )}
                      {tutor.es_creador === "1" ? (
                        <Chip
                          size="small"
                          variant="outlined"
                          style={{
                            borderColor: grey[500],
                            color: grey[500],
                            marginLeft: 5,
                          }}
                          label={"Creador"}
                        />
                      ) : (
                        <></>
                      )}
                    </AccordionSummary>
                    <AccordionDetails>
                      {/* <Stack direction='column'> */}

                      {tutor.liquidaciones.length > 0 && (
                        <TablaLiquidacion liquidaciones={tutor.liquidaciones} />
                      )}

                      {tutor.facturas.length > 0 && (
                        <TablaFacturas facturas={tutor.facturas} />
                      )}
                      {/* </Stack> */}
                    </AccordionDetails>
                  </Accordion>
                ) : (
                  <></>
                )}
              </>
            );
          })}
        </Box>
        {/* <div style={{ height: 500, width: '100%', padding:"0 15px" }}>
                      <DataGrid 
                          rows={avisos} 
                          columns={columnas} 
                          rowHeight={30}
                          getRowHeight={ () => 'auto' }
                          onRowDoubleClick={ (evento ) => handleAlumnoDetalle(evento.row.alumno_id) }
                          
                      />
                  </div> */}
      </>
    );
  };

  /* -------------------------------------------------------------------------- */
  /*                                   Return                                   */
  /* -------------------------------------------------------------------------- */
  return (
    <div className={classes.container}>
      {loading ? (
        <LoadingComponent isFullScreen={true} mensaje={loadingMensaje} />
      ) : (
        <>
          <ToastContainer position="bottom-center" />
          <Contenido />
        </>
      )}
    </div>
  );
}
