import React from 'react';
// import "../../assets/css/master.css";

import TemplateDashboard from "../../../components/TemplateDashboard";
import AvisosContent from './components/AvisosContent';
import AvisosSeguimientoContent from './components/AvisosSeguimientoContent';

const AvisosSeguimientoListado = () => {

    return (
        <>

            <TemplateDashboard>
                <AvisosSeguimientoContent />
            </TemplateDashboard>

        </>
    )
};

export default AvisosSeguimientoListado;