import { Button, FormControl, FormHelperText, Grid, InputLabel, makeStyles, MenuItem, Select } from '@material-ui/core';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { selectUsuarioSeleccionado } from '../../../redux/reducer/loginReducer';
import * as yup from 'yup';
import { alumno_tag_eliminar, getTags, insertar_alumno_tag } from '../../../services/alumnos';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';

/* -------------------------------------------------------------------------- */
/*                                   Estilos                                  */
/* -------------------------------------------------------------------------- */
const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: 90
        // display: 'flex',
        // flexWrap: 'wrap',
    },
    textField: {
        // marginLeft: theme.spacing(1),
        // marginRight: theme.spacing(1),
    },
    keyboardDatePicker: {
        // marginLeft: theme.spacing(1),
        // marginRight: theme.spacing(1),
    },
    formulario: {
        margin: 8
    },
    input: {
        display: 'none'
    },
}));

/* -------------------------------------------------------------------------- */
/*                            Esquema de valicación                           */
/* -------------------------------------------------------------------------- */
const validationSchema = yup.object({

    tag_id: yup.number().required("Tag requerido"),
    // apellidos: yup.string().required("Apellidos son requeridos"),
    // telefono: yup.string().required("Telefono es requerido"),
    // correo: yup.string().email().required("Correo es requerido"),
    
  });


const AlumnosTagsForm = ({alumno_id, tagsAlumno}) => {
    // // const classes = useStyles();

    // let history = useHistory();
    const usuarioSeleccionado = useSelector(state => {
        return selectUsuarioSeleccionado(state);
    });
    // const [loading, setLoading] = useState(false)
    // const [alumno, setAlumno] = useState(undefined)
    // const [notasSeguimientoAlumno, setNotasSeguimientoAlumno] = useState(notas_seguimiento)
    const [tags, setTags] = useState([])
    const [tagsAlumnoAux, setTagsAlumnoAux] = useState(tagsAlumno)

    const [initialValues, setInitialValues] = useState({
        tag_id: 0,
    })
    // const [notaSeleccionada, setNotaSeleccionada] = React.useState(undefined);
    // const [motivosLlamada, setMotivosLlamada] = React.useState(undefined)
    /* -------------------------------------------------------------------------- */
    /*                                  UseEffect                                 */
    /* -------------------------------------------------------------------------- */
    useEffect(() => {
        const usuario_id = usuarioSeleccionado.id
        
        getTags({ usuario_id }).then((response) => {
            response && setTags(response.tags);
        });

    // //     setLoading(true);
    
    //     getListaDetalle({usuario_id: usuario_id, lista_id: 16}).then( (response) => {
    //         response.lista_detalle && setMotivosLlamada(response.lista_detalle)
    //     })

    // // // const [comerciales, setComerciales] = useState(undefined)
    }, [])

    /* -------------------------------------------------------------------------- */
    /*                                  UseFormik                                 */
    /* -------------------------------------------------------------------------- */
    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            insertar_alumno_tag({
                usuario_id: usuarioSeleccionado.id,
                alumno_id: alumno_id,
                tag_id: values.tag_id,
            }).then( (response) => {
                
                setTagsAlumnoAux(response.alumno_tags)
                formik.motivo_llamada_id = 0
            })
        },
    });

      
    const columnas_tags = [
        {
            'field': 'tag_nombre', 
            flex: 1,
            'headerName': 'Tag', 
            'description': 'Tag',
        },
        {
            field: "actions",
            type: "actions",
            width: 80,
            getActions: (params) => [
              <GridActionsCellItem
                label="Quitar tag"
                onClick={(e) => {
                  alumno_tag_eliminar({
                    usuario_id: usuarioSeleccionado.id,
                    alumno_id: alumno_id,
                    tag_id: params.row.tag_id,
                  }).then((response) => {
                    response && setTagsAlumnoAux(response.alumno_tags)
                  });
                }}
                showInMenu
              />,
            ],
          },
    ];

    return (
        <>
            <form noValidate method="POST" enctype="multipart/form-data" autoComplete="off" onSubmit={ formik.handleSubmit }>

                <Grid container  spacing={2}>
                    
                    <Grid item xs={2}>
                        <FormControl
                            variant="outlined"
                            fullWidth
                            style={{ marginTop: 16 }}
                            error={formik.touched.tag_id && Boolean(formik.errors.tag_id)}
                        >
                            <InputLabel id="tag_id-label">Tag</InputLabel>
                            <Select
                                labelId="tag_id-label"
                                id="tag_id"
                                name="tag_id"
                                label="Tag"
                                fullWidth
                                value={formik.values.tag_id}
                                onChange={formik.handleChange}
                                error={formik.touched.tag_id && Boolean(formik.errors.tag_id)}
                            >
                                
                                {        
                                    tags && tags.map( (item) => {
                                        return (<MenuItem key={item.id} value={item.id}>{item.nombre}</MenuItem>)
                                    })
                                }

                            </Select>
                            <FormHelperText>{formik.touched.tag_id && formik.errors.tag_id}</FormHelperText>
                        </FormControl>
                    </Grid>

                    

                    <Grid item xs={12} md={1} >

                        <Button 
                            fullWidth
                            variant="contained" 
                            color="primary" 
                            type="submit"
                            size='large'
                            style={{marginTop: '16px'}}
                        >
                            Guardar
                        </Button>

                    </Grid>
                </Grid>
            </form>
            { 
                tagsAlumno && 
                    <div style={{ height: 500, width: '100%', padding:"" }}>
                        <DataGrid 
                            getRowHeight={() => 'auto'}
                            rows={tagsAlumnoAux} 
                            columns={columnas_tags} 
                            // onCellEditCommit={(params) => {
                            //     if(params.field === "fecha_aviso") {
                            //         update_nota_seguimiento_fecha_aviso({
                            //             usuario_id: usuarioSeleccionado.id,
                            //             nota_id: params.id,
                            //             fecha_aviso: params.value
                            //         }).then( (response) => {
                            //             console.log("🚀 ~ file: AlumnosNotasForm.js:278 ~ AlumnosNotasForm ~ response:", response)
                            //         })
                            //     }
                            //     if(params.field === "motivo_llamada_nombre") {
                            //         update_nota_seguimiento_motivo_llamada({
                            //             usuario_id: usuarioSeleccionado.id,
                            //             nota_id: params.id,
                            //             motivo_llamada_id: params.value
                            //         }).then( (response) => {
                            //             setNotasSeguimientoAlumno(response.notas_seguimiento)
                            //         })
                            //     }
                            // }}
                        />
                    </div>

                }


        </>
    )
};

export default AlumnosTagsForm;